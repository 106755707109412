<template>
  <div>
    <div v-if="collectionCompare">
      <h4>Apply content changes</h4>
      <div v-if="collectionCompare.hasChanges">
        <button class="btn btn-outline-secondary btn-sm" @click="apply">Apply</button>
        <button class="btn btn-outline-secondary btn-sm">Save</button>
        <button class="btn btn-outline-secondary btn-sm">Revert</button>
      </div>
      <div v-else class="alert alert-warning" role="alert">
        No changes were made. Please fix errors and
        <a href="#" class="alert-link">try again</a>!
      </div>
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th>ChartName</th>
            <th>Vers</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="chart in charts" :key="chart.name">
          <td>{{chart.chartName}}</td>
          <td>
            <span v-if="!chart.isChanged">{{chart.oldEdtn}}.{{chart.oldUpdn}}</span>
            <span v-else>
              <span>{{chart.oldEdtn}}.{{chart.oldUpdn}} => {{chart.newEdtn}}.{{chart.newUpdn}}</span>
            </span>
          </td>
          <td>
            <div v-if="chart.errors != null" class="alert alert-danger">
              <ul class="list list-unstyled">
                <li v-for="error in chart.errors" :key="error">{{error}}</li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
      <div v-if="collectionCompare.hasChanges">
        <button class="btn btn-outline-secondary btn-sm" @click="apply">Apply</button>
        <button class="btn btn-outline-secondary btn-sm">Save</button>
        <button class="btn btn-outline-secondary btn-sm">Revert</button>
      </div>
      <div v-else class="alert alert-warning" role="alert">
        No changes were made. Please fix errors and
        <a href="#" class="alert-link">try again</a>!
      </div>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
export default {
  props: ["collectionCompare", "guid"],
  computed: {
    charts() {
      return this.collectionCompare.charts;
    }
  },
  methods: {
    apply() {
      myAxios
        .get("/api/geocontent/ApplyEnc?guid=" + this.guid)
        .then(resp => {
          console.log(resp.data);
          if (resp.data) {
            this.$router.push({
              name: "catalog"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>