<template>
    <div>
        <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
                <router-link to="/distributor2/orders" class="nav-link"
                    v-bind:class="{ active: isActive('orders') }">Orders</router-link>
            </li>
            <li class="nav-item">
                <router-link to="/distributor2/agreements" class="nav-link"
                    v-bind:class="{ active: isActive('agreements') }">Agreements</router-link>
            </li>
        </ul>
        <router-view name="distributorNewView"></router-view>
    </div>
</template>

<script>
export default {
    methods: {
        isActive(name) {
            return this.$route.name === name
        }
    }
}
</script>