import * as geoContentService from '@/services/geoContentService'
const initialState = {
    geoContent: null,
    geoContents: null,
    status: null,
    licenses: null,
    products: null
}

export const geoContent = {
    namespaced: true,
    state: initialState,
    actions: {
        getGeoContents({ dispatch, commit }, id) {
            commit('geoContentsRequest');
            geoContentService.getGeoContents()
                .then(resp => {
                    var geoContents = resp.data;
                    if (geoContents) {
                        commit('geoContentsSuccess', geoContents);
                    }
                    else {
                        commit('geoContentsFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('geoContentsFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        getGeoContent({ dispatch, commit }, id) {
            commit('geoContentRequest');
            geoContentService.getGeoContent(id)
                .then(resp => {
                    var geoContent = resp.data;

                    if (geoContent) {
                        commit('geoContentSuccess', geoContent);
                    }
                    else {
                        commit('geoContentFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('geoContentFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        getLicenses({ dispatch, commit }, gcid) {
            geoContentService.getLicenses(gcid).then(resp => {
                commit('gcLicenses', resp.data)
            })
        },
        addPermit({ dispatch, commit }, { email, gcid, prodid, exp, devnum }) {
            commit('geoContentRequest');
            geoContentService.addPermit(email, prodid, exp, devnum)
                .then(resp => {
                    dispatch('getGeoContent', gcid);
                    dispatch('getLicenses', gcid);
                })
                .catch(error => {
                    commit('geoContentFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        removePermit({ dispatch, commit }, { userid, gcid, prodid }) {
            commit('geoContentRequest');
            geoContentService.removePermit(userid, prodid)
                .then(resp => {
                    dispatch('getGeoContent', gcid);
                    dispatch('getLicenses', gcid);
                })
                .catch(error => {
                    commit('geoContentFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        addUpdateProduct({ dispatch, commit }, prod) {
            commit('geoContentRequest');
            geoContentService.addUpdateProduct(prod)
                .then(resp => {
                    dispatch('getGeoContent', prod.gcid);
                })
                .catch(error => {
                    commit('geoContentFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        geoContentRequest(state) {
            state.status = { loading: true };
            state.geoContent = null;
        },
        geoContentSuccess(state, geoContent) {
            state.status = { loaded: true };
            state.geoContent = geoContent;
            //state.licenses = geoContent.licenses;
        },
        geoContentFailure(state) {
            state.status = null;
            state.geoContent = null;
        },

        geoContentsRequest(state) {
            state.status = { loading: true };
            state.geoContents = null;
        },
        geoContentsSuccess(state, geoContents) {
            state.status = { loaded: true };
            state.geoContents = geoContents;
        },
        geoContentsFailure(state) {
            state.status = null;
            state.geoContents = null;
        },
        gcLicenses(state, licenses) {
            state.licenses = licenses;
        },
        processing(state, boolVal) {
            state.status = { processing: boolVal };
        },
        uploading(state, boolVal) {
            state.status = { uploading: boolVal };
        }
    },
    getters: {
        gcid: state => {
            if (state.geoContent) {
                return state.geoContent.id;
            }
            return null;
        }
    }
}