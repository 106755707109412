<template>
    <div>
        <h6>Agreement Users</h6>
        <table class="table table-bordered">
            <tr v-for="user in users" :key="user.installationId">
                <td class="link" @click="showTransactions(user.userId, null)">{{ user.email }} ({{ user.userId }})</td>
                <td>{{ getAppById(user.appId) }} ({{ getPlatformById(user.platformId) }})</td>
                <td class="link" @click="showTransactions(user.userId, user.installationId)">
                    <span v-if="user.shipName">ship name: {{ user.shipName }}</span><span v-else>{{ user.deviceId }}</span>
                </td>
                <td><my-date :dateTime="user.createdAt" /></td>
            </tr>
        </table>
    </div>
</template>

<script>
import MyDate from '@/components/MyDate.vue';

export default {
    props: {
        users: [],
        agreementId: null
    },
    methods: {
        getAppById(id) {
            switch (id) {
                case 0: return "mKart"
                case 1: return "NjordPilot"
                case 2: return "Vega"
                case 10: return "dKart"
                case 11: return "ECDIS"
                default: return "n/a"
            }
        },
        getPlatformById(id) {
            switch (id) {
                case 1: return "WinPC"
                case 2: return "WinCE"
                case 3: return "Android"
                case 4: return "iOS"
                default: return "n/a"
            }
        },
        showTransactions(userId, instId) {
            this.$router.push({ name: "transactions", params: { agreementId: this.agreementId, userId, instId } })
        }
    }
}
</script>

<style>
table{
  font-size: 15px;
}
.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>