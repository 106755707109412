<template>
  <div>
    <div v-if="chartCollection == null">
      <div class="form-group">
        <select class="form-control" v-model="sourceType">
          <option value="0">Select source type</option>
          <option value="s57">S57 chart files</option>
          <option value="s63">S63 zip archive</option>
        </select>
      </div>
      <add-enc class="mt-2 mb-4" v-if="sourceType === 's57'"></add-enc>
      <add-s-63 v-if="sourceType === 's63'"></add-s-63>
    </div>

    <div v-if="chartCollection && status && status.loaded">
      <!-- <p>{{ $tc('publisher.gc.cat.enc.list.total', chartCollection.length) }}</p> -->

      <div v-if="chartCollection.type == 's63'">
        <p>
          Converted from S63 source {{ meta.ZipFileName }} at <my-date :date="meta.Timestamp"></my-date>
        </p>
      </div>

      <table class="table table-sm" v-if="chartCollection.charts.length > 0">
        <thead>
          <tr>
            <th>{{ $t("publisher.gc.cat.enc.list.chartName") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.ver") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.lastUpd") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.scale") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.band") }}</th>
            <th>{{ $t("publisher.gc.cat.enc.list.descr") }}</th>
          </tr>
        </thead>
        <tr v-for="chart in chartCollection.charts" :key="chart.id">
          <td>{{ chart.name }}</td>
          <td>{{ chart.edition }}.{{ chart.update }}</td>
          <td><my-date :date="chart.releaseDate"></my-date></td>
          <td>{{ chart.scale }}</td>
          <td>{{ chart.band }}</td>
          <td>{{ chart.description }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import AddEnc from "@/components/geocontent/AddEnc";
import AddS63 from "./AddS63.vue";

export default {
  components: { AddEnc, AddS63 },
  data() {
    return {
      sourceType: "0",
    };
  },
  computed: {
    status() {
      return this.$store.state.chart.status;
    },
    chartCollection() {
      return this.$store.state.chart.chartCollection;
    },
    meta(){
      return JSON.parse(this.chartCollection.metaInfo);
    }
  },
};
</script>

<style scoped>
th {
  color: grey;
}
</style>