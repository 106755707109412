<template>
  <div>
    <div
      v-if="showNewGeoContentForm"
      class="form-group"
      style="max-width: 600px"
    >
      <h5>Add new geocontent</h5>
      <label>Geocontent Title</label>
      <input
        type="text"
        class="form-control"
        v-model="contentName"
        placeholder="Title (min. 5 chars)"
      />
      <div class="mt-2">
        <button
          class="btn btn-outline-primary mr-1"
          :disabled="contentName.length < 5"
          @click="createNewGeoContent()"
        >
          Submit
        </button>
        <button
          class="btn btn-outline-secondary"
          @click="showNewGeoContentForm = false"
        >
          Cancel
        </button>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-6">
          <div v-if="provider" class="d-flex flex-row align-items-baseline">
            <div v-if="geoContent">
              <h4>{{ geoContent.title }}</h4>
            </div>
            <div v-if="geoContent">
              <p class="mx-3">{{ $t("publisher.publishedby") }}</p>
            </div>
            <div>
              <h4>{{ provider.title }}</h4>
            </div>
          </div>
        </div>
        <div class="col-6 d-flex flex-row justify-content-end">
          <div
            class="mr-1"
            v-if="geoContent && provider && provider.geoContents.length > 1"
          >
            <select
              class="form-control form-control-sm"
              @change="onGeocontentChange($event)"
            >
              <option value="0">switch between geoContents</option>
              <option
                v-for="gc in provider.geoContents.filter(
                  (o) => o.id !== geoContent.id
                )"
                :key="gc.id"
                :value="gc.id"
              >
                {{ gc.title }}
              </option>
            </select>
          </div>
          <div>
            <button
              class="btn btn-outline-secondary btn-sm"
              @click="showNewGeoContentForm = true"
            >
              add new geocontent
            </button>
          </div>
        </div>
      </div>
      <!-- <p>{{ $t("publisher.s57encnp") }}</p> -->
      <div v-if="geoContent && geoContent.id">
        <router-view name="providerView" :gcid="geoContent.id"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import myAxios from "@/services/myAxios";

export default {
  name: "ContentProviderCabinet",
  data() {
    return {
      contentName: "",
      showNewGeoContentForm: false,
    };
  },
  created() {
    this.$store.dispatch("provider/getProvider");
  },
  computed: {
    status() {
      return this.$store.state.provider.status;
    },
    provider() {
      return this.$store.state.provider.provider;
    },
    currentGeoContentId() {
      return this.$store.getters["provider/gcid"];
    },
    geoContent() {
      return this.$store.getters["provider/geoContent"];
    },
  },
  methods: {
    createNewGeoContent() {
      myAxios
        .get("/api/geocontent/create2", {
          params: {
            gcTitle: this.contentName,
            providerTitle: this.provider.title,
          },
        })
        .then((resp) => {
          if (resp.data) {
            this.$store.dispatch(
              "provider/setCurrentGeocontentId",
              resp.data.id
            );
            this.$store.dispatch("provider/getProvider");
            this.showNewGeoContentForm = false;
          }
        });
    },
    onGeocontentChange(event) {
      if (event.target.value > 0) {
        this.$store.dispatch(
          "provider/setCurrentGeocontentId",
          event.target.value
        );
      }
    },
  },
};
</script>