<template>
  <div>
    <div class="d-none d-xl-block">
      <div style="margin-left:-7px;" class="mb-1">
        <img src="../assets/images/mkart_logo.jpg" width="50px" />
        <span class="ml-2 brandname align-bottom">mKart</span>
      </div>
      <hr />
    </div>
    <div class="row">
      <div class="col-md-9">
        <!-- <div class="row" id="user_types">
          <div class="col m-2 p-2 d-flex flex-column">
            {{ $t('home.users') }}
            <router-link class="mt-auto" to="/registration/user">Registartion</router-link>
          </div>
          <div class="col m-2 p-2 d-flex flex-column">
            {{ $t('home.publishers') }}
            <router-link class="mt-auto" to="/registration/provider">Registartion</router-link>
          </div>
          <div class="col m-2 p-2 d-flex flex-column">
            {{ $t('home.distributors') }}
            <router-link class="mt-auto" to="/registration/distributor">Registartion</router-link>
          </div>
          <div class="col m-2 p-2 d-flex flex-column">
            {{ $t('home.resellers') }}
            <router-link class="mt-auto" to="/registration/reseller">Registartion</router-link>
          </div>
        </div>-->

        <sign-up-sign-in v-if="!auth.status.loggedIn"></sign-up-sign-in>
        <how-it-works></how-it-works>
      </div>
      <!-- <div class="col-md-3 pt-2" v-if="geoContents && status.loaded">
        <div class="card mb-3" v-for="gc in geoContents" :key="gc.id">
          <div class="card-body">
            <h5 class="card-title">{{gc.title}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">by {{gc.provider.title}}</h6>
            <p class="card-text">S57 ENCs and Nautical Publications</p>
          </div>
        </div>
      </div>-->
      <div class="col-md-3 pt-2">
        <news></news>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpSignIn from "@/components//signUpSignIn/index.vue";
import HowItWorks from "@/components/homepage/HowItWorks.vue";
import News from "@/components/homepage/News.vue";

export default {
  components: {
    SignUpSignIn,
    HowItWorks,
    News
  },
  created() {
    this.$store.dispatch("geoContent/getGeoContents");
  },
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    geoContents() {
      return this.$store.state.geoContent.geoContents;
    },
    auth() {
      return this.$store.state.auth;
    }
  }
};
</script>

<style scoped>
.brandname {
  font-size: xx-large;
  font-weight: bold;
}

#user_types > div {
  background-color: #eee9e9;
  border-radius: 5px;
}

#user_types > div:hover {
  background-color: #ffe2aa;
}

/* #user_types > div > h3 {
  margin-bottom: 50px;
} */

a:not([href]) {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.card-text {
  font-size: small;
}
</style>