<template>
    <div>
        <div v-if="fetching" class="badge text-bg-info">loading data... please wait!</div>
        <div v-else>
            <button @click="$router.push({ path: 'agreements/' + query.agreementId })">Back</button>
            <button @click="showAllAgreementTransactions()">Show All</button>
            <table class="table">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>Timestamp</th>
                        <th>AgreementId</th>
                        <th>UserId</th>
                        <th>InstId</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Balance</th>
                        <th>Limit</th>
                        <th>UserLimit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in transactions" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td><my-date :dateTime="item.timestamp" /></td>
                        <td>{{ item.agreementId }}</td>
                        <td>{{ item.userId }}</td>
                        <td>{{ item.installationId ?? '-' }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.balance }}</td>
                        <td>{{ item.agreementLimit ?? '-' }}</td>
                        <td>{{ item.userLimit ?? '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import myAxios from '@/services/myAxios';
import MyDate from '@/components/MyDate.vue';

export default {
    data() {
        return {
            transactions: [],
            fetching: false,
            query: this.$route.params
        }
    },
    mounted() {
        this.queryTransactions()
    },
    methods: {
        queryTransactions() {
            this.fetching = true
            myAxios.post("/api/agreement/transactions-query", this.query)
                .then(resp => { this.transactions = resp.data })
                .finally(() => {
                    this.fetching = false
                })
        },
        showAllAgreementTransactions() {
            var agreementId = this.query.agreementId
            this.query = { agreementId }
            this.queryTransactions()
        }
    }
}
</script>
