import * as distributorService from '@/services/distributorService'
const initialState = {
    distributor: null,
    status: null,
    issuedLicenses: null
}

export const distributor = {
    namespaced: true,
    state: initialState,
    actions: {
        getDistributor({ dispatch, commit }) {
            commit('distributorRequest');
            distributorService.getDistributor()
                .then(resp => {
                    var distributor = resp.data;
                    if (distributor) {
                        commit('distributorSuccess', distributor);
                    }
                    else {
                        commit('distributorFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('distributorFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        },
        issueLicense({ dispatch, commit }, { email, prodid, exp, devnum }) {
            console.log('hola');
            commit('issuingLicense');
            distributorService.issueLicense({ email, prodid, exp, devnum }).then(resp => {
                if (resp.data) {
                    commit('issuedLicense');
                    dispatch('getIssuedLicenses', prodid)
                }
            })
        },
        getIssuedLicenses({ dispatch, commit }, prodid ) {
            commit('loadingLicenses');
            distributorService.getIssuedLicenses(prodid).then(resp => {
                commit('getLicensesSuccess', resp.data);
            })
        }
    },
    mutations: {
        distributorRequest(state) {
            state.status = { loading: true };
            state.distributor = null;
        },
        distributorSuccess(state, distributor) {
            state.status = { loaded: true };
            state.distributor = distributor;
        },
        distributorFailure(state) {
            state.status = null;
            state.distributor = null;
        },
        issuingLicense(state) {
            state.status = null;
            state.status = { issuing: true };
        },
        issuedLicense(state) {
            state.status = null;
            state.status = { issued: true };
        },
        loadingLicenses(state) {
            state.issuedLicenses = null;
        },
        getLicensesSuccess(state, licenses){
            state.issuedLicenses = licenses;
        }
    }
}