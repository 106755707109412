<template>
  <div>
    <div class="mt-4">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>Created</th>
            <th>Filename</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="(itm, indx) in depthList" :key="itm.id">
          <td>{{ indx + 1 }}.</td>
          <td><my-date :date="itm.timestamp"></my-date></td>
          <td>{{ itm.filename }}</td>
          <td>{{ itm.description }}</td>
          <td>
            <button
              v-if="!itm.isPublished"
              class="btn btn-sm btn-outline-primary"
              @click="publish(itm.id)"
            >
              Publish
            </button>
            <button
              v-else
              class="btn btn-sm btn-outline-warning"
              @click="hide(itm.id)"
            >
              Hide
            </button>
            <button class="ml-2 btn btn-sm btn-outline-danger">Delete</button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return { depthList: null };
  },
  created() {
    this.getList();
  },
  computed: {
    geoContentId() {
      return this.$store.getters["provider/gcid"];
    },
  },
  methods: {
    getList() {
      if (this.geoContentId) {
        myAxios
          .get("/api/geocontent/GetRawDepthList?geoContentId=" + this.geoContentId)
          .then((resp) => {
            this.depthList = resp.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    publish(rdid) {
      myAxios
        .get("/api/geocontent/PublishRawDepth?rdid=" + rdid)
        .then((resp) => {
          if (resp.data == true) {
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hide(rdid) {
      myAxios
        .get("/api/geocontent/HideRawDepth?rdid=" + rdid)
        .then((resp) => {
          if (resp.data == true) {
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    geoContentId: function () {
      this.getList();
    },
  },
};
</script>

<style>
.formbox {
  border: solid 1px rgb(201, 201, 201);
  border-radius: 5px;
}
</style>