<template>
  <div>
    <h4>Shopping Cart</h4>
    <table class="table" v-if="shop.cart">
      <tr v-for="(itm, index) in shop.cart" :key="itm.id">
        <td>{{index+1}}</td>
        <td>{{itm.name}}</td>
        <td>{{itm.price}} RUB</td>
        <td>
          <button class="btn btn-sm btn-outline-warning" @click="removeItem(itm)">Remove Item</button>
        </td>
      </tr>
      <tr>
        <th></th>
        <th>TOTAL</th>
        <th>{{total}} RUB</th>
        <th>
          <div>
            <button class="btn btn-success mr-3">Checkout</button>
            <button class="btn btn-outline-danger" @click="clearCart">Clear Cart</button>
          </div>
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["items"],
  computed: {
    shop() {
      return this.$store.state.shop;
    },
    total() {
      return this.$store.getters["shop/total"];
    }
  },
  methods: {
    removeItem(item) {
      this.$store.dispatch("shop/removeFromCart", item);
    },
    clearCart() {
      this.$store.dispatch("shop/clearCart");
    }
  }
};
</script>