<template>
    <div>
        <div>
            <h6>Add to Agreement</h6>
            <div class="row mb-3">
                <div class="col-3">
                    <input class="form-control" type="text" placeholder="Find user by email" v-model="email" />
                </div>
                <div class="col-1">
                    <button class="btn btn-outline-primary" type="button" @click="findUserByEmail()">Find</button>
                </div>
            </div>
            <p v-if="user === ''">No such user</p>
            <div v-else>
                <div>{{ user.email }}</div>
                <div v-if="user && user.installations">
                    <table v-if="user.installations.length > 0" class="table">
                        <tr v-for="inst in user.installations" :key="inst.id">
                            <td>{{ getAppById(inst.appId) }} ({{ getPlatformById(inst.platformId) }})</td>
                            <td>{{ inst.shipName }} {{ inst.deviceCode }}</td>
                            <td>
                                <div><button class="btn btn-sm btn-outline-primary"
                                        @click="addUserToAgreement(inst.id)">Add to Agreement</button></div>
                            </td>
                        </tr>
                    </table>
                    <p v-else>No suitable installations found</p>
                </div>
            </div>
        </div>
        <hr />
        <user-list :users="users" :agreementId="agreementId"></user-list>
    </div>
</template>

<script>
import myAxios from '@/services/myAxios';
import UserList from './UserList.vue';

export default {
    components: { UserList },
    data() {
        return {
            email: null,
            user: {},
            users: [],
            agreementId: this.$route.params.id
        }
    },
    mounted() {
        this.getAgreementUsers()
    },
    methods: {
        getAgreementUsers() {
            myAxios.get("/api/agreement/agreement-users", { params: { agreementId: this.$route.params.id } })
                .then(resp => { this.users = resp.data })
        },
        findUserByEmail() {
            myAxios.get("/api/agreement/get-user-for-agreement", { params: { email: this.email, agreementId: this.$route.params.id } })
                .then(resp => { this.user = resp.data })
        },
        addUserToAgreement(installationId) {
            myAxios.post("/api/agreement/add-user-inst", { agreementId: this.$route.params.id, userId: this.user.userId, installationId })
                .then(() => {
                    this.getAgreementUsers()
                    this.findUserByEmail()
                })
        },
        getAppById(id) {
            switch (id) {
                case 0: return "mKart"
                case 1: return "NjordPilot"
                case 2: return "Vega"
                case 10: return "dKart"
                case 11: return "ECDIS"
                default: return "n/a"
            }
        },
        getPlatformById(id) {
            switch (id) {
                case 1: return "WinPC"
                case 2: return "WinCE"
                case 3: return "Android"
                case 4: return "iOS"
                default: return "n/a"
            }
        }
    }
}
</script>