import * as chartService from '@/services/chartService'
const initialState = {
    chartCollection: null,
    status: null,
}

export const chart = {
    namespaced: true,
    state: initialState,
    actions: {
        getChartCollection({ dispatch, commit, rootState }) {
            commit('chartCollectionRequest');
            var gcid = rootState.provider.currentGeocontentId
            chartService.getChartCollection(gcid)
                .then(resp => {
                    var chartCollection = resp.data;
                    if (chartCollection) {
                        commit('chartCollectionSuccess', chartCollection);
                    }
                    else {
                        commit('chartCollectionFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('chartCollectionFailure', error.message);
                    dispatch('alert/error', error.message, { root: true });
                });
        }
    },
    mutations: {
        chartCollectionRequest(state) {
            state.status = { loading: true };
            state.chartCollection = null;
        },
        chartCollectionSuccess(state, chartCollection) {
            state.status = { loaded: true };
            state.chartCollection = chartCollection;
        },
        chartCollectionFailure(state) {
            state.status = null;
            state.chartCollection = null;
        },
    }
}