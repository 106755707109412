<template>
    <div>
        <div v-if="fetching" class="badge text-bg-info">loading data... please wait!</div>
        <div v-else>
            <create-agreement :fetchAgreements="fetchAgreements"></create-agreement>
            <div id="agreemnet_list">
                <table class="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Title</th>
                            <th>Distributor</th>
                            <th>Type</th>
                            <th>Currency</th>
                            <th>SignedAt</th>
                            <th>ValidTill</th>
                        </tr>
                    </thead>
                    <tr v-for="agreement in agreements" :key="agreement.id">
                        <td><router-link :to="'/distributor2/agreements/' + agreement.id">{{
                            agreement.id
                        }}</router-link></td>
                        <td>{{ agreement.name }}</td>
                        <td>{{ agreement.distributor.title }}</td>
                        <td>{{ agreement.type }}</td>
                        <td>{{ agreement.currency }}</td>
                        <td><my-date :date="agreement.signedAt" /></td>
                        <td><my-date :date="agreement.validTill" /></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import MyDate from '../../../components/MyDate.vue';
import myAxios from "@/services/myAxios";
import CreateAgreement from "./CreateAgreement.vue"

export default {
    components: {
        CreateAgreement
    },
    data() {
        return {
            agreements: [],
            fetching: true
        }
    },
    mounted() {
        this.fetchAgreements()
    },
    methods: {
        fetchAgreements() {
            myAxios.post("/api/agreement/query", {}).then(resp => {
                this.agreements = resp.data
                this.fetching = false
            })
        },
        arrayToString(jsonString) {
            if (jsonString) {
                return JSON.parse(jsonString).join(", ")
            }
            return ""
        }
    }
}
</script>