<template>
  <div>
      <div class="card" v-if="locale.code==='ru'">
      <div class="card-header">Новости</div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <small style="color:grey;font-size:13px">20/04/2020</small>
            <p>
              <small>Выпущена пред-релизная версия программы</small>
            </p>
            <p style="color:grey;font-size:12px">Это финальная бета перед публикацией mKart</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-header">News</div>
      <div class="card-body p-0">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <small style="color:grey;font-size:13px">2020/04/20</small>
            <p>
              <small>mKart pre-release version is available</small>
            </p>
            <p style="color:grey;font-size:12px">This is final beta build before release.</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  }
};
</script>