<template>
  <div>
    <div v-if="error">{{ error }}</div>
    <div v-if="fetching" class="badge text-bg-info">loading data... please wait!</div>
    <div v-else>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>ID</th>
            <th>Customer</th>
            <th>Agreement</th>
            <th>Price Total</th>
            <th>Created At</th>
            <th>Items</th>
            <th>AppName</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tr v-for="order in orders" :key="order.id">
          <td>{{ order.id }}</td>
          <td>
            <div class="customer-info">
              <div>{{ order.user.email }} ({{ order.user.id }})</div>
              <div v-if="order.device.shipName"><small>ship name: {{ order.device.shipName }}</small></div>
              <div><small>device id: {{ order.device.deviceId }}</small></div>
            </div>
          </td>
          <td>{{ order.agreementId }}</td>
          <td>{{ order.priceTotal }}</td>
          <td><my-date :dateTime="order.createdAt" /></td>
          <td><order-items :items="order.orderItems" /></td>
          <td>{{ getAppName(order.device.applicationTypeId) }}</td>
          <td>{{ displayStatus(order.status) }}</td>
          <td>
            <div v-if="isOrderProcessible(order)">
              <button class="btn btn-sm btn-outline-primary" @click="processOrder(order.id)" :disabled="buttonPushed">Process</button>
              <button style="margin-left:5px;" class="btn btn-sm btn-outline-danger"
                @click="cancelOrder(order.id)" :disabled="buttonPushed">Cancel</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import OrderItems from "./OrderItems.vue";
import MyDate from '../../../components/MyDate.vue';

export default {
  components: { OrderItems, MyDate },
  data() {
    return {
      orders: [],
      fetching: true,
      error: null,
      timer: null,
      buttonPushed: false
    };
  },
  mounted() {
    this.fetchAgreementOrders();

    this.timer = setInterval(() => {
      this.fetchAgreementOrders();
    }, 15000);
  },
  beforeDestroy(){
    clearInterval(this.timer);
  },
  methods: {
    displayStatus(status) {
      return status.split('_').join(' ');
    },
    fetchAgreementOrders() {
      this.buttonPushed = true;
      myAxios.post("/api/agreement/order-list", {})
        .then((resp) => {
          this.orders = resp.data;
        })
        .catch(err => {
          this.error = JSON.stringify(err.response.data).split(':')[0] ?? err.message
        })
        .finally(() => {
          this.fetching = false;
          this.buttonPushed = false;
        });
    },
    processOrder(orderId) {
      this.buttonPushed = true;
      myAxios.get("/api/agreement/process-order?orderId=" + orderId)
        .then(() => {
          this.fetching = true;
          this.fetchAgreementOrders()
        })
        .catch(err => {
          this.error = JSON.stringify(err.response.data).split(':')[0] ?? err.message
        })
        .finally(() => {
          this.buttonPushed = false;
        })
    },
    cancelOrder(orderId) {
      this.buttonPushed = true;
      myAxios.post("/api/agreement/cancel-order", { orderId })
        .then(() => {
          this.fetching = true;
          this.fetchAgreementOrders()
        })
        .catch(err => {
          this.error = JSON.stringify(err.response.data).split(':')[0] ?? err.message
        })
        .finally(() => {
          this.buttonPushed = false;
        })
    },
    isOrderProcessible(order) {
      return order.status == "new" || order.status == "owner_approved"
    },
    getAppName(appId) {
      switch (appId) {
        case 0: return "mKart";
        case 1: return "NjordPilot";
        case 2: return "Vega";
        case 10: return "dKart";
        case 11: return "ECDIS"
        default: return "undefined";
      }
    }
  },
};
</script>

<style>
table{
  font-size: 15px;
}
div.customer-info>div {
  line-height: 18px;
}
</style>