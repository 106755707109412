<template>
  <div>
    <nav class="nav mt-3">
      <div class="nav-link pl-0">
        <button class="btn btn-outline-primary" @click="goToMap">
          <i class="fas fa-globe"></i> Map
        </button>
      </div>
      <loc-link
        :to="{name: 'enc'}"
        :prms="{ upDate: 'test' }"
        class="nav-link"
        aclass="my-active"
      >{{ $t('publisher.gc.cat.indx.enc') }}</loc-link>
      <loc-link :to="{name: 's102'}" class="nav-link" aclass="my-active">S-102</loc-link>
      <loc-link :to="{name: 'ntm'}" class="nav-link" aclass="my-active">NTM</loc-link>
      <loc-link :to="{name: 'poi'}" class="nav-link" aclass="my-active">POI</loc-link>
      <loc-link :to="{name: 'msg'}" class="nav-link" aclass="my-active">NavMessages</loc-link>
      <span class="nav-link" aclass="my-active">AIS</span>
      <span class="nav-link" aclass="my-active">{{ $t('publisher.gc.cat.indx.wthr') }}</span>
      <loc-link :to="{name: 'raw'}" class="nav-link" aclass="my-active">Raw</loc-link>
    </nav>
    <router-view name="gcCatalogView"></router-view>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    },
  },

  methods: {
    goToMap() {
      myAxios
        .get("api/geocontent/gotomap?gcid=" + this.geoContent.id)
        .then((resp) => {
          this.$router.push({ name: "map", params: { taskId: resp.data } });
        })
        .catch((err) => {
          console.log(222);
        });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: x-large;
  padding-bottom: 1px !important;
  margin-bottom: 15px;
}
.my-active {
  font-weight: bold;
  
  border-bottom: solid 4px #FF8500;
  /* color: #c07607 */
}
span.nav-link {
  color: rgb(177, 177, 177);
}
</style>