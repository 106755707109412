import User from '../views/user'
import Cart from '../views/eshop/Cart.vue'
import Provider from '../views/provider/Home.vue'
import GeoContent from '../views/provider/geocontent/index.vue'
import Licenses from '../views/provider/geocontent/Licenses.vue'
import MyMap from '../views/provider/geocontent/Map.vue'
import Catalog from '../views/provider/geocontent/catalog'
import Login from '../views/Login.vue'
import Home from '../views/Home'
import Registartion from '../views/registration'
import Distributor from '../views/distributor/Home.vue'
import DistributorNew from '../views/distributor2'
import Orders from '../views/distributor2/orders'
import Agreements from '../views/distributor2/agreements'
import UpdateAgreement from '../views/distributor2/agreements/UpdateAgreement.vue'

import Reseller from '../views/reseller/Home.vue'
//import AddEnc from '@/components/geocontent/AddEnc'
import Enc from '../views/provider/geocontent/catalog/enc'
import Raw from '../views/provider/geocontent/catalog/raw'
import EncList from '../views/provider/geocontent/catalog/enc/EncList.vue'
import S102 from '../views/provider/geocontent/catalog/s102'
import S102List from '../views/provider/geocontent/catalog/s102/s102List.vue'
import Ntm from '../views/provider/geocontent/catalog/ntm'
import Poi from '../views/provider/geocontent/catalog/poi/PoiList.vue'
import Confirm from '../views/provider/geocontent/catalog/enc/Confirm.vue'
import NavMessages from '../views/provider/geocontent/catalog/NavMessages'
import ProviderRegistartion from '../views/provider/ProviderRegistration.vue'
import DistributorRegistartion from '../views/distributor/DistributorRegistration.vue'
import Products from '../views/distributor/Products.vue'
import Distributors from '../views/provider/geocontent/Distributors.vue'
import DistributorLicenses from '../views/distributor/Licenses.vue'
import PublisherProducts from '../views/provider/geocontent/Products.vue'
import CustomProducts from '../views/distributor/CustomProducts/index.vue'
import AgreementTransactions from '@/views/distributor2/agreements/transactions/index.vue'
import OrderManager from '../views/manager/orders/index.vue'
import LicenseActivator from '../views/license-activator/index.vue'


export default [
  {
    path: 'user',
    name: 'user',
    components: { default: User },
    meta: { role: 'user' },
    props: { default: true }
  },
  {
    path: 'user/cart',
    name: 'cart',
    components: { default: Cart },
    meta: { role: 'user' },
    props: { default: true }
  },
  {
    name: 'providerRegistration',
    component: ProviderRegistartion,
    path: 'provider/registration',
    meta: { role: 'user' }
  },
  {
    name: 'distributorRegistration',
    component: DistributorRegistartion,
    path: 'distributor/registration',
    meta: { role: 'user' }
  },
  {
    path: 'map',
    name: 'map',
    component: MyMap,
    meta: { role: 'provider' },
    props: true
  },
  {
    path: 'provider',
    name: 'provider',
    component: Provider,
    meta: { role: 'provider' },
    redirect: { name: 'list' },
    children: [
      {
        name: 'geocontent',
        meta: { role: 'provider' },
        path: 'geocontent',
        components: {
          providerView: GeoContent
        },
        children: [
          {
            path: 'licenses',
            name: 'licenses',
            components: {
              geoContentView: Licenses
            },
            meta: { role: 'provider' },
            props: true
          },
          {
            path: 'products',
            name: 'products',
            components: {
              geoContentView: PublisherProducts
            },
            meta: { role: 'provider' },
            props: true
          },
          {
            path: 'distributors',
            name: 'my_distributors',
            components: {
              geoContentView: Distributors
            },
            meta: { role: 'provider' },
            props: true
          },
          {
            path: 'catalog',
            name: 'catalog',
            components: { geoContentView: Catalog },
            meta: { role: 'provider' },
            props: true,
            redirect: { name: 'list' },
            children: [
              {
                path: 'enc',
                name: 'enc',
                components: {
                  gcCatalogView: Enc
                },
                meta: { role: 'provider' },
                props: true,
                redirect: { name: 'list' },
                children: [
                  {
                    name: 'list',
                    path: 'list',
                    components: {
                      encView: EncList
                    },
                    meta: { role: 'provider' },
                    props: {
                      encView: true
                    },
                  },
                  {
                    name: 'confirm',
                    path: 'confirm',
                    components: {
                      encView: Confirm
                    },
                    meta: { role: 'provider' },
                    props: {
                      encView: true
                    }
                  }
                ]
              },
              {
                path: 's102',
                name: 's102',
                components: {
                  gcCatalogView: S102
                },
                meta: { role: 'provider' },
                props: true,
                redirect: { name: 's102-list' },
                children: [
                  {
                    name: 's102-list',
                    path: 'list',
                    components: {
                      encView: S102List
                    },
                    meta: { role: 'provider' },
                    props: {
                      encView: true
                    },
                  },
                ]
              },
              {
                path: 'poi',
                name: 'poi',
                components: {
                  gcCatalogView: Poi
                },
                meta: { role: 'provider' },
                props: true
              },
              {
                path: 'ntm',
                name: 'ntm',
                components: {
                  gcCatalogView: Ntm
                },
                meta: { role: 'provider' },
                props: true
              },
              {
                path: 'msg',
                name: 'msg',
                components: {
                  gcCatalogView: NavMessages
                },
                meta: { role: 'provider' },
                props: true
              },
              {
                path: 'raw',
                name: 'raw',
                components: {
                  gcCatalogView: Raw
                },
                meta: { role: 'provider' },
                props: true
              }
            ]
          },
        ]
      }]
  },
  {
    path: 'distributor',
    name: 'distributor',
    redirect: 'distributor/licenses',
    component: Distributor,
    meta: { role: 'distributor' },
    children: [
      {
        components: {
          distributorView: Products
        },
        name: 'distributor_products',
        path: 'products',
        meta: { role: 'distributor' }
      },
      {
        components: {
          distributorView: DistributorLicenses
        },
        name: 'distributor_licenses',
        path: 'licenses',
        meta: { role: 'distributor' }
      },
      {
        components: {
          distributorView: CustomProducts
        },
        name: 'distributor_custom_products',
        path: 'custom-products',
        meta: { role: 'distributor' }
      }
    ]
  },
  {
    path: 'distributor2',
    name: 'distributor2',
    redirect: 'distributor2/orders',
    component: DistributorNew,
    meta: { role: 'distributor' },
    children: [
      {
        path: 'orders',
        name: 'orders',
        components: { distributorNewView: Orders },
        meta: { role: 'distributor' }
      },
      {
        path: 'agreements',
        name: 'agreements',
        components: { distributorNewView: Agreements },
        meta: { role: 'distributor' }
      },
      {
        path: 'agreements/:id',
        name: 'update-agreement',
        components: { distributorNewView: UpdateAgreement },
        meta: { role: 'distributor' }
      },
      {
        path: 'transactions',
        name: 'transactions',
        components: { distributorNewView: AgreementTransactions },
        props: true,
        meta: { role: 'distributor' }
      }
    ]
  },
  {
    path: 'order-manager',
    name: 'order-manager',
    component: OrderManager,
    meta: { role: 'distributor' }
  },
  {
    path: 'reseller',
    name: 'reseller',
    component: Reseller,
    meta: { role: 'reseller' },
    // children: [{ component: Registartion, path: 'registartion' }]
  },
  {
    path: 'login',
    name: 'login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: 'activ8license',
    name: 'activate',
    component: LicenseActivator,
    meta: { guest: true }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    //this is for redirect user to cabinet after login
    // beforeEnter: (to, from, next) => {
    //   let userRecord = localStorage.getItem('user');
    //   if (userRecord == null) {
    //     next()
    //   }
    //   else {
    //     let user = JSON.parse(userRecord)
    //     next({ path: "/" + user.roles[0].role.toLowerCase() });
    //   }
    // },
    meta: { guest: true }
  },
  {
    path: 'registration',
    name: 'registartion',
    component: Registartion,
    meta: { guest: true }
  },
  // {
  //   path: '/',
  //   redirect: to => {
  //     var user = JSON.parse(localStorage.getItem('user'));
  //     if (user) {
  //       return { path: "/" + user.roles[0].role.toLowerCase() };
  //     }
  //     else {
  //       return { path: '/login' }
  //     }
  //   }
  // },
  {
    path: '*',
    redirect: 'home'
  }
]