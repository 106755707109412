<template>
  <div class="list-group-item">
    <h6>Add S63 ENC Product</h6>
    <div v-if="!uploading" style="max-width: 450px">
      <div class="form-group">
        <label>DeviceKey</label>
        <input type="text" class="form-control" v-model="deviceKey" />
      </div>
      <div class="custom-file">
        <input
          :key="timestamp"
          type="file"
          class="custom-file-input"
          id="customFile"
          @change="onFilesSelected"
          accept=".zip"
        />
        <label class="custom-file-label" for="customFile">{{
          s63zipPlaceholder
        }}</label>
      </div>
      <div class="pt-3">
        <button
          class="btn btn-outline-primary"
          :disabled="!isSubmittable"
          @click="addProduct()"
        >
          Add
        </button>
        <button class="btn btn-outline-secondary ml-2" @click="clearForm">
          Cancel
        </button>
      </div>
    </div>
    <div v-else>
      <p v-if="uploadPercentage < 105">Uploading...</p>
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped bg-success"
          role="progressbar"
          :style="uploadPercentageWithPercent"
          :aria-valuenow="getPercentage"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p v-if="uploadPercentage === 105" class="mt-4">
        Files uploaded. Convertation will take some time. You may close this
        browser's tab.
      </p>
      <div v-else-if="uploadingError" class="alert alert-warning" role="alert">
        Uploading failed. Please try again or contact tech support.
      </div>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
  props: {
    prods: null,
  },
  data() {
    return {
      timestamp: new Date().getTime(),
      file: null,
      deviceKey: null,
      uploadPercentage: 0,
      uploadingError: false,
      uploading: false,
    };
  },
  computed: {
    isSubmittable() {
      return this.file && this.deviceKey;
    },
    uploadPercentageWithPercent() {
      return "width:" + (this.uploadPercentage - 5).toString().concat("%");
    },
    getPercentage() {
      return this.uploadPercentage - 5;
    },
    s63zipPlaceholder() {
      if (this.file) return this.file.name;
      return "ENCs zip archive";
    },
    currentGeoContentId() {
      return this.$store.getters["provider/gcid"];
    },
  },
  methods: {
    addProduct() {
      if (this.currentGeoContentId) {
        var formData = new FormData();
        formData.append("file", this.file);
        formData.append("deviceKey", this.deviceKey);
        formData.append("geocontentId", this.currentGeoContentId);
        this.uploading = true;

        myAxios
          .post("/api/geocontent/AddS63ToGeocontent", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              console.log(this.uploadPercentage);
            }.bind(this),
          })
          .then(() => {
            this.uploadPercentage += 5;
          })
          .catch((err) => {
            this.uploadingError = true;
            console.log(err);
          });
      }
    },
    clearForm() {
      this.file = null;
      this.deviceKey = null;
      this.timestamp = new Date().getTime();
    },
    onFilesSelected(event) {
      var files = Object.values(event.target.files);
      this.file = files[0];
    },
  },
};
</script>