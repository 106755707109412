<template>
    <div>
        <button v-if="mode === 'button'" class="btn btn-sm btn-primary" @click="mode = 'form'">Create Agreement</button>
        <div v-else style="width:600px;">
            <div v-if="distributors">
                <label class="form-label">Distributor</label>
                <select class="form-select" v-model="form.distributorId">
                    <option>Select Distributor</option>
                    <option v-for="distributor in distributors" :key="distributor.id" :value="distributor.id">{{
                        distributor.title }} ({{ distributor.mainUser.email }})</option>
                </select>
            </div>
            <div>
                <label class="form-label">Title</label>
                <input type="text" class="form-control" v-model="form.name" />
            </div>
            <div class="row mb-4">
                <div class="col">
                    <label class="form-label">Type</label>
                    <select class="form-select" v-model="form.type">
                        <option value="com">commercial</option>
                        <option value="svc">service</option>
                    </select>
                </div>
                <div class="col">
                    <label class="form-label">Currency</label>
                    <select class="form-select" v-model="form.currency">
                        <option value="RUB">RUB</option>
                        <option value="USD">USD</option>
                    </select>
                </div>
                <div class="col">
                    <label class="form-label">Valid Till</label>
                    <input class="form-control" type="date" v-model="form.validTill">
                </div>
            </div>
            <div>
                <label class="form-label">Supervisor</label>
                <div class="row mb-3">
                    <div class="col-5">
                        <input class="form-control" type="text" placeholder="Find user by email"
                            v-model="supervisorEmail" @keyup="supervisorNotFound=false" />
                    </div>
                    <div class="col-1" v-if="supervisorEmail !== currentSupervisorEmail || form.supervisorId == null">
                        <button class="btn btn-outline-primary" type="button" @click="findSupervisorByEmail()">Find</button>
                    </div>
                </div>
                <div v-if="supervisorNotFound">No such user!</div>
            </div>
            <div class="d-grid gap-2 d-md-block mb-3">
                <button class="btn btn-primary" type="submit" @click.once="submit()"
                    :disabled="!isSubmittable">Submit</button>&nbsp;
                <button class="btn btn-danger" type="button" @click="cancel()">Cancel</button>
            </div>
            <div v-if="errors" class="alert alert-warning alert-dismissible fade show" role="alert">
                {{ errors }}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
    data() {
        return {
            mode: "button",
            form: {
                validTill: null,
                type: "com",
                distributorId: null,
                currency: "RUB",
                name: null,
                supervisorId: null
            },
            errors: null,
            distributors: null,
            supervisorEmail: null,
            supervisorNotFound: false,
            currentSupervisorEmail: null
        }
    },
    props: {
        fetchAgreements: {
            type: Function,
            required: true
        }
    },
    mounted() {
        this.getDistributorList()
    },
    computed: {
        isSubmittable() {
            return this.form.validTill && this.form.distributorId
        }
    },
    methods: {
        getDistributorList() {
            myAxios.post("/api/distributors/query", {})
                .then(resp => {
                    this.distributors = resp.data
                })
        },
        submit() {
            myAxios.post("/api/agreement/create", this.form)
                .then(() => {
                    this.fetchAgreements()
                    this.cancel()
                })
                .catch(err => {
                    this.errors = err.response.data
                })
        },
        cancel() {
            this.form = {
                validTill: null,
                type: "com",
                name: null,
                supervisorId: null
            }
            this.mode = "button"
        },
        findSupervisorByEmail() {
            this.supervisorNotFound = false
            myAxios.post("/api/agreement/get-user-by-email?email=" + this.supervisorEmail)
                .then(resp => {
                    if (resp.data) {
                        this.form.supervisorId = resp.data.id
                        this.currentSupervisorEmail = this.supervisorEmail
                    }
                    else {
                        this.supervisorNotFound = true
                    }
                })
                .catch(err => {
                    this.errors = err.response.data
                })
        }
    }
}
</script>