<template>
    <div>
        <ul class="list-unstyled mb-0">
            <li v-for="item in itemsToShow" :key="item.id">
                {{ item.id }}
            </li>
            <li class="link" v-if="items.length > showItemsCount" @click="showItemsCount = items.length"><small>show all {{ items.length }} items</small></li>
            <li class="link" v-if="items.length > 1 && items.length === showItemsCount" @click="showItemsCount = 1"><small>hide charts list</small></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "OrderItems",
    data(){
        return {
            showItemsCount: 1
        }
    },
    props: {
        items: []
    },
    computed: {
        itemsToShow() {
            return this.items.slice(0, this.showItemsCount)
        }
    }
}
</script>

<style>
.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
</style>