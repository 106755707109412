<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th></th>
                    <th v-for="prod in products" :key="prod" class="text-center">{{ prod }}</th>
                </tr>
            </thead>
            <RatesForApp v-for="app in apps" :key="app" :appName="app" :rates="getAppRates(app)"
                :allProducts="products" />
        </table>
    </div>
</template>

<script>
import RatesForApp from './RatesForApp.vue';
import { eventBus } from '@/main'

export default {
    components: { RatesForApp },
    props: {
        rates: []
    },
    data() {
        return {
            currentRates: JSON.parse(JSON.stringify(this.rates)),
            products: ["Primar", "NOAA", "NHO"],
            apps: ["mKart", "Vega", "ECDIS", "dKart", "NjordPilot"]
        }
    },
    mounted() {
        eventBus.$on("rateChanged", this.onRateChanged)
        this.currentRates = JSON.parse(JSON.stringify(this.rates))
    },
    methods: {
        getAppRates(appName) {
            return Array.isArray(this.currentRates) ? this.currentRates.find(o => o.name === appName) : null
        },
        onRateChanged(rate) {
            if (!Array.isArray(this.currentRates)) return
            var appIndex = this.currentRates.findIndex(o => o.name === rate.appName)

            if (appIndex >= 0) {
                var prodRateIndex = this.currentRates[appIndex].prods.findIndex(o => o.name === rate.rate.name)

                if (prodRateIndex >= 0) {
                    if (rate.rate.rates[0].net) {
                        this.currentRates[appIndex].prods[prodRateIndex] = rate.rate
                    }
                    else {
                        this.currentRates[appIndex].prods.splice(prodRateIndex, 1);
                        if (this.currentRates[appIndex].prods.length == 0) {
                            this.currentRates.splice(appIndex, 1)
                        }
                    }
                }
                else {
                    this.currentRates[appIndex].prods.push(rate.rate)
                }
            }
            else if (rate.rate.rates[0].net) {
                var app = { name: rate.appName, prods: [] }
                app.prods.push(rate.rate)
                this.currentRates.push(app)
            }

            eventBus.$emit("ratesChangeComplete", this.currentRates)
        }
    }
}
</script>