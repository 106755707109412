<template>
    <div>
        <div v-if="fetching" class="badge text-bg-info">loading data... please wait!</div>
        <div v-else-if="agreementLimits">
            <p>Overall Limit: <input type="text" v-model="agreementLimits.agreementLimit" /><button
                    @click="setAgreementLimit">Set</button></p>
            <p>
                Limits by Users:
            <table>
                <user-limit v-for="userLimit in agreementLimits.usersLimits" :key="userLimit.id"
                    :agreement-id="agreementId" :userLimit="userLimit" />
            </table>
            </p>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/main';
import myAxios from '@/services/myAxios';
import UserLimit from './UserLimit.vue';


export default {
    components: {
        UserLimit
    },
    props: {
        agreementId: null
    },
    data() {
        return {
            agreementLimits: null,
            fetching: false
        }
    },
    mounted() {
        eventBus.$on("refreshLimits", () => { this.fetchAgreementLimits() })

        this.fetchAgreementLimits()
    },
    methods: {
        fetchAgreementLimits() {
            this.fetching = true
            myAxios.get("/api/agreement/limits?agreementId=" + this.agreementId)
                .then(resp => {
                    this.agreementLimits = resp.data
                }).finally(() => {
                    this.fetching = false
                })

        },
        setAgreementLimit() {
            var limit = this.agreementLimits.agreementLimit ? this.agreementLimits.agreementLimit : null
            myAxios.post("/api/agreement/set-agreement-limit", { agreementId: this.agreementId, limit })
                .then(() => {
                    this.fetchAgreementLimits()
                })
        }
    }
}
</script>