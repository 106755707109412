<template>
    <tr>
        <td>{{ order.order.id }}</td>
        <td><my-date :dateTime="order.order.createdAt" /></td>
        <td>
            <div class="customer-info">
                <div>{{ order.order.user.email }}</div>
                <div v-if="order.shipName"><small>ship name: <strong>{{ order.shipName }}</strong></small></div>
            </div>
        </td>
        <td><order-items :items="order.order.orderItems" /></td>
        <td>
            <div class="customer-info">
                <div v-if="order.order.paymentMethod === 'agreement'">
                    <div><small>distributor: {{ order.agreement.distributorName }}</small></div>
                    <div><router-link :to="'/distributor2/agreements/' + order.agreement.agreementId"><small>agreement:
                                <span v-if="order.agreement.name">{{ order.agreement.name }}</span>
                                <span v-else>id: {{ order.agreement.agreementId }}</span></small></router-link>
                    </div>
                </div>
                <div v-else>{{ order.order.paymentMethod }}</div>
            </div>
        </td>
        <td>{{ order.order.currency }} {{ order.order.priceTotal }}</td>
        <td>{{ displayStatus(order.order.status) }}</td>
        <td>
            <div>
                <div v-if="error" class="alert alert-warning" role="alert"
                    style="width: 200px;line-height: 18px;font-size: 14px;">
                    <p>{{ error }}</p>
                    <button class="btn btn-sm btn-warning" @click="error = null">Ok</button>
                </div>
                <div v-else-if="order.order.status === 'owner_approved' || order.order.status === 'new'">
                    <button class="btn btn-sm btn-outline-primary" @click="processOrder(order.order.id)"
                        :disabled="buttonPushed">Process</button>
                    <button style="margin-left:3px;" class="btn btn-sm btn-outline-secondary"
                        @click="cancelOrder(order.order.id)" :disabled="buttonPushed">Cancel</button>
                </div>
                <div v-if="hasYearSubscriptions" class="badge text-bg-warning">warning: long subscription!</div>
            </div>
        </td>
    </tr>
</template>

<script>
import myAxios from "@/services/myAxios";
import MyDate from '@/components/MyDate.vue';
import OrderItems from "./OrderItems.vue";
import { eventBus } from '@/main';

export default {
    components: { OrderItems },
    props: {
        order: {}
    },
    data() {
        return {
            error: null,
            buttonPushed: false
        }
    },
    computed:{
        hasYearSubscriptions() {
            if (this.order.order.orderItems.filter(o => o.duration > 6).length > 0) return true
            return false
        }
    },
    methods: {
        processOrder(orderId) {
            this.buttonPushed = true;
            myAxios.get("/api/agreement/process-order?orderId=" + orderId)
                .then(() => {
                    eventBus.$emit("refreshOrder", this.order.order.id)
                })
                .catch(err => {
                    this.error = err.response.data ?? err.message
                })
                .finally(() => {
                    this.buttonPushed = false;
                })
        },
        cancelOrder(orderId) {
            this.buttonPushed = true;
            myAxios.post("/api/agreement/cancel-order", { orderId })
                .then(() => {
                    eventBus.$emit("refreshOrder", this.order.order.id)
                })
                .catch(err => {
                    this.error = err.response.data ?? err.message
                })
                .finally(() => {
                    this.buttonPushed = false;
                })
        },
        displayStatus(status) {
            return status.split('_').join(' ');
        }
    }
}
</script>