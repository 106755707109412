<template>
    <tr>
        <td>{{ userLimit.email }}</td>
        <td><input type="number" v-model="limit" /></td><button @click="setUserLimit()">Set</button>
    </tr>
</template>

<script>
import myAxios from '@/services/myAxios';
import { eventBus } from '@/main';

export default {
    props: {
        agreementId: null,
        userLimit: {}
    },
    data() {
        return {
            limit: this.userLimit.limit
        }
    },
    methods: {
        setUserLimit() {
            var limit = this.limit ? this.limit : null
            myAxios.post("/api/agreement/set-user-limit", { agreementId: this.agreementId, userId: this.userLimit.userId, limit })
                .then(() => {
                    eventBus.$emit("refreshLimits")
                })
        }
    }
}
</script>