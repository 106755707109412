<template>
  <span>{{ mydate }}</span>
</template>

<script>
import moment from "moment";

export default {
  props: {
    date: null,
    dateTime: null
  },
  computed: {
    mydate() {
      if (this.date) {
        return moment(this.date).format("YYYY-MM-DD");
      }
      if(this.dateTime){
        return moment(this.dateTime).format("YYYY-MM-DD HH:MM:SS");
      }
      return "unlimited";
    },
  },
};
</script>