<template>
  <div>
    <div class="row">
      <div class="col">{{prod.title}}</div>
      <div class="col"><my-date :date="prod.issuedAt" /></div>
      <div class="col"><my-date :date="prod.expiredAt" /></div>
    </div>
    <div>
      <div class="row" v-for="item in prod.productItems" :key="item.id">
        <div class="col">{{item.type}}</div>
        <div class="col"><a :href="item.url">External link</a></div>
        <div class="col">
            <button
                  class="btn btn-sm btn-outline-primary"
                  @click="deleteItem(prod.productId, item.id)"
                >
                  Del
                </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prod: {},
  },
  methods:{
      deleteItem(productId, itemId){}
  }
};
</script>