<template>
    <div>
        <div v-if="fetching" class="badge text-bg-info">loading data... please wait!</div>
        <div v-else>
            <h5 v-if="agreement.distributor">{{ agreement.distributor.title }} ({{
            agreement.distributor.mainUser.email
        }})</h5>
            <div class="nav nav-pills mb-3">
                <a class="nav-link" v-bind:class="{ active: mode === 'main' }" @click="mode = 'main'" href="#">Main</a>
                <a class="nav-link" v-bind:class="{ active: mode === 'users' }" @click="mode = 'users'"
                    href="#">Users</a>
                <a class="nav-link" v-bind:class="{ active: mode === 'limits' }" @click="mode = 'limits'"
                    href="#">Limits</a>
            </div>
            <div v-if="mode === 'main'" style="width:900px;">
                <div class="row mb-4">
                    <div class="col"><label class="form-label">Title</label>
                        <input class="form-control" type="text" v-model="form.name">
                    </div>
                    <div class="col">
                        <label class="form-label">Type</label>
                        <select class="form-select" v-model="form.type">
                            <option value="com">commercial</option>
                            <option value="svc">service</option>
                        </select>
                    </div>

                    <div class="col">
                        <label class="form-label">Valid Till</label>
                        <input class="form-control" type="date" v-model="form.validTill">
                    </div>
                </div>

                <div class="mb-3">
                    <rates-table :rates="form.rates"></rates-table>
                </div>
                <div class="d-grid gap-2 d-md-block mb-3">
                    <button class="btn btn-primary" type="submit" @click.once="submit()"
                        :disabled="!isSubmittable">Submit</button>&nbsp;
                    <button v-if="isChanged" class="btn btn-warning" type="button" @click.once="cancel()">Undo
                        Changes</button>
                </div>
                <div v-if="errors" class="alert alert-warning alert-dismissible fade show" role="alert">
                    {{ errors }}
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            </div>
            <div v-else-if="mode == 'users'">
                <agreement-users></agreement-users>
            </div>
            <div v-else-if="mode === 'limits'">
                <agreement-limits :agreementId="agreement.id" />
            </div>
        </div>
    </div>
</template>

<script>
import myAxios from '@/services/myAxios';
import moment from 'moment';
import RatesTable from './rates/index.vue';
import AgreementUsers from './users/index.vue'
import AgreementLimits from './Limits/index.vue'
import { eventBus } from '@/main'

export default {
    components: {
        RatesTable,
        AgreementUsers,
        AgreementLimits
    },
    data() {
        return {
            initialForm: {
                validTill: null,
                type: "com",
                rates: {},
                name: ""
            },
            form: {
                validTill: null,
                type: "com",
                rates: {},
                name: ""
            },
            agreement: {},
            errors: null,
            fetching: false,
            mode: 'users'
        }
    },
    computed: {
        isSubmittable() {
            return this.form.validTill
        },
        isChanged() {
            return JSON.stringify(this.form) !== JSON.stringify(this.initialForm)
        }
    },
    mounted() {
        this.fetchAgreement()
        eventBus.$on("ratesChangeComplete", (rates) => { this.form.rates = rates })
    },
    methods: {
        fetchAgreement() {
            this.fetching = true
            myAxios.get("/api/agreement?id=" + this.$route.params.id).then(resp => {
                this.agreement = resp.data.agreement
                this.form.name = this.agreement.name
                this.form.validTill = moment(this.agreement.validTill).format("YYYY-MM-DD");
                this.form.type = this.agreement.type
                this.form.rates = resp.data.rates
                this.initialForm = JSON.parse(JSON.stringify(this.form))
                this.fetching = false
            })
        },
        cancel() {
            this.fetchAgreement()
        },
        submit() {
            var agreement = {
                id: this.agreement.id,
                ...this.form
            }
            this.fetching = true
            myAxios.post("/api/agreement/update", agreement)
                .then(() => {
                    this.$router.push("/distributor2/agreements");
                })
                .catch(err => {
                    this.fetching = false
                    this.errors = err.response.data
                })
        }
    }
}
</script>