<template>
  <div>
    <div v-if="status.loaded" class="mb-4">
      <!-- <p>Email: {{user.email}}</p> -->
      <user-licenses v-bind:licenses="user.licenses" v-if="user.licenses.length > 0"></user-licenses>
      <!-- <custom-products v-if="user.email.endsWith('@probki.net')"></custom-products> -->
    </div>
  </div>
</template>

<script>
import UserLicenses from "@/components/UserLicenses";
// import CustomProducts from "./custom-products";

export default {
  name: "UserCabinet",
  components: { UserLicenses },
  created() {
    this.$store.dispatch("user/getUser");
  },
  computed: {
    status() {
      return this.$store.state.user.status;
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>