<template>
  <div v-if="geoContent">
    <add-user-permit :gcid="geoContent.id" class="my-3"></add-user-permit>
    <p v-if="licenses && licenses.length == 0">{{ $t('publisher.gc.lics.nolics') }}</p>
    <table class="table table-sm mt-3" v-else>
      <thead>
        <tr>
          <th>{{ $t('publisher.gc.lics.issued') }}</th>
          <th>{{ $t('publisher.gc.lics.expired') }}</th>
          <th>{{ $t('publisher.gc.lics.lictype') }}</th>
          <th>{{ $t('publisher.gc.lics.devnum') }}</th>
          <th>{{ $t('publisher.gc.lics.userid') }}</th>
          <th>{{ $t('publisher.gc.lics.issuer') }}</th>
          <th></th>
        </tr>
      </thead>
      <tr v-for="lic in licenses" :key="lic.id">
        <td><my-date :date="lic.license.issued"></my-date></td>
        <td><my-date :date="lic.license.expirationDate"></my-date></td>
        <td>{{lic.license.product.licType}} <span v-if="lic.license.product.licType === 'com'">({{ $t('publisher.gc.lics.price') }}: {{lic.license.price}})</span></td>
        <td>{{lic.license.deviceCount}}</td>
        <td>{{lic.license.user.email}}</td>
        <td>
          <span v-if="lic.issuer">{{lic.issuer.title}} ({{lic.license.issuerType}})</span>
          <i v-else-if="lic.license.issuerType === 'iOS'" class="fab fa-apple"></i>
          <i v-else-if="lic.license.issuerType === 'Android'" class="fab fa-android"></i>
          <span v-else-if="lic.license.issuerType === 'publisher'"> - </span>
        </td>
        <td>
          <!-- <button
            v-if="lic.license.issuerType === 'publisher'"
            class="btn btn-sm btn-outline-danger"
            @click="removePermit(lic.license.user.id)"
          >remove</button>-->
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import AddUserPermit from "@/components/AddUserPermit";

export default {
  components: {
    AddUserPermit
  },
  // created() {
  //   this.$store.dispatch("geoContent/getLicenses", this.geoContent.id);
  // },
  computed: {
    licenses() {
      return this.$store.state.geoContent.licenses;
    },
    geoContent() {
      return this.$store.state.geoContent.geoContent;
    }
  },
  methods: {
    removePermit(userid) {
      this.$store.dispatch("geoContent/removePermit", {
        gcid: this.geoContent.id,
        userid
      });
    },
    prodShort(license) {
      var prod = license.product
      var short = prod.licType.toUpperCase() + " [";
      if (prod.enc) short += "ENC, ";
      if (prod.ais) short += "AIS, ";
      if (prod.ntm) short += "NTM, ";
      if (prod.poi) short += "POI, ";
      if (prod.weather) short += "WTHR, ";
      if (prod.noaaWeather) short += "WTHR(NOAA), ";
      if (prod.navMsg) short += "NMSG, ";
      short = short.slice(0, -2);
      short += "]";
      if (prod.licType === "com") {
        short += " - Price: " + license.price;
      }
      return short;
    }
  }
};
</script>

<style scoped>
th {
  color: grey;
}

i.fab {
  color: #0069D9;
}
</style>