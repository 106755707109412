<template>
    <tr style="border-bottom:1px 0">
        <td class="text-center">{{ appName }}</td>
        <td v-for="prod in allProducts" :key="prod">
            <rates-input :rates="getProductRate(prod)" :appName="appName" :prodName="prod"></rates-input>
        </td>
    </tr>
</template>

<script>
import RatesInput from './RatesInput.vue'

export default {
    components: { RatesInput },
    props: {
        appName: {},
        allProducts: [],
        rates: {}
    },
    methods: {
        getProductRate(prodName) { 
            if (this.rates) {
                return this.rates.prods.find(o => o.name === prodName)
            }
        }
    }
}
</script>