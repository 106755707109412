import * as providerService from '@/services/providerService'
const initialState = {
    provider: null,
    status: null,
    currentGeocontentId: null
}

export const provider = {
    namespaced: true,
    state: initialState,
    actions: {
        getProvider({
            dispatch,
            commit
        }) {
            commit('providerRequest');
            providerService.getProvider()
                .then(resp => {
                    var provider = resp.data;
                    if (provider) {
                        commit('providerSuccess', provider);
                        dispatch('chart/getChartCollection', null, {
                            root: true
                        })
                    } else {
                        commit('providerFailure', 'error.message');
                    }
                })
                .catch(error => {
                    commit('providerFailure', error.message);
                    dispatch('alert/error', error.message, {
                        root: true
                    });
                });
        },
        setCurrentGeocontentId({
            dispatch,
            commit
        }, geoContentId) {
            commit('setCurrentGeocontentId', geoContentId);
            dispatch('chart/getChartCollection', null, {
                root: true
            })
            dispatch('geoContent/getLicenses', geoContentId, {
                root: true
            })
        }
    },
    mutations: {
        providerRequest(state) {
            state.status = {
                loading: true
            };
            state.provider = null;
        },
        providerSuccess(state, provider) {
            state.status = {
                loaded: true
            };
            state.provider = provider;
            state.currentGeocontentId = this.setCurrentGeocontentId ?? state.provider.geoContents[state.provider.geoContents.length - 1].id
        },
        providerFailure(state) {
            state.status = null;
            state.provider = null;
        },
        setCurrentGeocontentId(state, geoContentId) {
            state.currentGeocontentId = geoContentId
        }
    },
    getters: {
        gcid: state => {
            if (state.currentGeocontentId) return state.currentGeocontentId;
            return state.provider.geoContents[0].id;
        },
        geoContent: state => {
            if (state.provider && state.provider.geoContents) {
                if (state.currentGeocontentId) return state.provider.geoContents.find(o => o.id == state.currentGeocontentId);
                return state.provider.geoContents[0]
            }
        }
    }
}