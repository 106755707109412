<template>
  <div>
    <h5 class="mt-2 mb-3">Custom Products</h5>
    <div class="row">
      <div class="col-3">
        <div>
          <h6>Users</h6>
          <button
            v-if="currentUser"
            @click="flushCurrentUser()"
            class="btn btn-sm btn-outline-primary my-2"
          >
            Add new
          </button>
          <ul class="list-unstyled">
            <li
              v-for="user in users"
              :key="user.userId"
              @click="onUserPicked(user)"
              class="clickable"
            >
              {{ user.email }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-9">
        <div>
          <h5 v-if="currentUser">{{ currentUser.email }}</h5>
          <p>Add New Product</p>
          <div class="form-group" v-if="currentUser == null">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Title"
              v-model="title"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Url"
              v-model="url"
            />
          </div>
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="onSubmit()"
              :disabled="!isSubmittable"
            >
              Submit
            </button>
          </div>
        </div>
        <div v-if="userProducts.length">
          <custom-product v-for="prod in userProducts" :key="prod.productId" :prod="prod"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import CustomProduct from "./CustomProduct.vue";

export default {
  components: {
    CustomProduct,
  },
  data() {
    return {
      users: [],
      userProducts: [],
      currentUser: null,
      email: "",
      title: "",
      url: "",
    };
  },
  mounted() {
    this.getCustomProductsUsers();
  },
  computed: {
    isSubmittable() {
      return this.email && this.title && this.url;
    },
  },
  methods: {
    getCustomProductsUsers() {
      myAxios.get("/api/custom-products/users").then((resp) => {
        this.users = resp.data;
      });
    },
    onSearchInput(email) {
      myAxios
        .post("/api/custom-products/search-by-email", { email })
        .then((resp) => {});
    },
    onUserPicked(user) {
      this.currentUser = user;
      this.email = user.email;
      myAxios
        .get("/api/custom-products/user-products/" + user.userId)
        .then((resp) => {
          this.userProducts = resp.data;
        });
    },
    onSubmit() {
      var request = {
        email: this.email,
        title: this.title,
        productItems: [{ type: "S63ENC", url: this.url }],
      };

      myAxios.post("/api/custom-products", request).then((resp) => {
        this.getCustomProductsUsers();

        this.title = "";
        this.url = "";

        if (this.currentUser) {
          this.onUserPicked(this.currentUser);
        }
      });
    },
    flushCurrentUser() {
      this.currentUser = null;
      this.userProducts = [];
      this.email = "";
      this.title = "";
      this.url = "";
    },
    deleteProduct(productId) {
      if (confirm("Are you sure?")) {
        myAxios.delete("/api/custom-products/" + productId).then((resp) => {
          this.onUserPicked(this.currentUser);
        });
      }
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>