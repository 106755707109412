<template>
  <div>
    <form v-if="!uploading">
      <div class="custom-file">
        <input type="file" multiple class="custom-file-input" id="customFile" @change="uploadFiles" />
        <label class="custom-file-label" for="customFile">{{ $t('publisher.gc.cat.enc.add.input') }}</label>
      </div>
    </form>
    <div v-else>
      <p v-if="uploadPercentage<105">{{ $t('publisher.gc.cat.enc.add.uploading') }}</p>
      <div class="progress">
        <div
          class="progress-bar progress-bar-striped bg-success"
          role="progressbar"
          :style="uploadPercentageWithPercent"
          :aria-valuenow="getPercentage"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p
        v-if="uploadPercentage===105"
        class="mt-4"
      >{{ $t('publisher.gc.cat.enc.add.upload_success') }}</p>
      <div
        v-else-if="uploadingError"
        class="alert alert-warning"
        role="alert"
      >{{ $t('publisher.gc.cat.enc.add.upload_failed') }}</div>
    </div>
  </div>
</template>
<script>
import myAxios from "@/services/myAxios";

export default {
  data() {
    return {
      uploadPercentage: 0,
      uploading: false,
      uploadingError: false
    };
  },
  computed: {
    status() {
      return this.$store.state.geoContent.status;
    },
    gcid() {
      return this.$store.state.geoContent.geoContent.id;
    },
    uploadPercentageWithPercent() {
      return "width:" + (this.uploadPercentage - 5).toString().concat("%");
    },
    getPercentage() {
      return this.uploadPercentage - 5;
    }
  },

  methods: {
    uploadFiles(event) {
      var formData = new FormData();
      var files = Object.values(event.target.files);
      files.forEach(file => {
        formData.append("files", file);
      });
      formData.append("gcid", this.gcid);
      //this.$store.commit("geoContent/uploading", true);
      this.uploading = true;
      console.log(formData.getAll("files"));
      myAxios
        .post("/api/geocontent/addenc", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            console.log(this.uploadPercentage);
          }.bind(this)
        })
        .then(resp => {
          this.uploadPercentage += 5;
          //this.$store.commit("geoContent/uploading", false);
          //this.$store.commit("geoContent/processing", true);
          // this.$router.push({
          //   name: "confirm",
          //   params: { guid: resp.data.taskGuid, collectionCompare: resp.data }
          // });
        })
        .catch(err => {
          this.uploadingError = true;
          //this.$store.commit("geoContent/uploading", false);
          console.log(err);
        });
    }
  }
};
</script>