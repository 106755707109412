<template>
    <div class="container" style="max-width:260px">
        <div v-if="!isValid" style="color:red">All values should be null or valid number!</div>
        <div class="row row-cols-3">
            <div class="col"></div>
            <div class="col">
                net
            </div>
            <div class="col">
                end-user
            </div>

            <div class="col">USD</div>
            <div class="col">
                <input type="number" class="form-control form-control-sm rate-input" v-model="usd.net">
            </div>
            <div class="col">
                <input type="number" class="form-control form-control-sm rate-input" v-model="usd.endUser">
            </div>

            <div class="col">RUB</div>
            <div class="col">
                <input type="number" class="form-control form-control-sm rate-input" v-model="rub.net">
            </div>
            <div class="col">
                <input type="number" class="form-control form-control-sm rate-input" v-model="rub.endUser">
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/main'

export default {
    props: {
        rates: {},
        appName: null,
        prodName: null
    },
    data() {
        return {
            usd: {
                net: null,
                endUser: null
            },
            rub: {
                net: null,
                endUser: null
            }
        }
    },
    computed: {
        isValid() {
            if ((this.usd.net == null || this.usd.net.length == 0)
                && (this.usd.endUser == null || this.usd.endUser.length == 0)
                && (this.rub.net == null || this.rub.net.length == 0)
                && (this.rub.endUser == null || this.rub.endUser.length == 0)) {
                return true
            }
            else if (this.isNumeric(this.usd.net) && this.isNumeric(this.usd.endUser) && this.isNumeric(this.rub.net) && this.isNumeric(this.rub.endUser)) {
                return true
            }
            return false
        }
    },
    mounted() {
        if (this.rates) {
            this.usd = this.rates.rates.find(o => o.currency === "USD")
            this.rub = this.rates.rates.find(o => o.currency === "RUB")
        }
    },
    updated(){
        if (this.rates) {
            this.usd = this.rates.rates.find(o => o.currency === "USD")
            this.rub = this.rates.rates.find(o => o.currency === "RUB")
        }
    },
    methods: {
        buildRates() {
            return {
                appName: this.appName,
                rate: {
                    name: this.prodName,
                    rates: [
                        { currency: "RUB", net: this.nullable(this.rub.net), endUser: this.nullable(this.rub.endUser) },
                        { currency: "USD", net: this.nullable(this.usd.net), endUser: this.nullable(this.usd.endUser) }
                    ]
                }
            }
        },
        isNumeric(str) {
            if (typeof str === "number") return true
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        nullable(val) {
            if (val) return val
            return null
        }
    },
    watch: {
        usd:
        {
            handler:
                function () {
                    if (this.isValid) {
                        var rates = this.buildRates()
                        eventBus.$emit("rateChanged", rates)
                    }
                }
            ,
            deep: true
        },
        rub:
        {
            handler:
                function () {
                    if (this.isValid) {
                        var rates = this.buildRates()
                        eventBus.$emit("rateChanged", rates)
                    }
                }
            ,
            deep: true
        }
    }
}
</script>

<style scoped>
.rate-input {
    width: 70px;
}
</style>