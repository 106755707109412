<template>
    <div style="width: 350px;">
        <div v-if="activationCode">
            Your Activation Code: <h4>{{ activationCode }}</h4>
        </div>
        <form class="form" @submit.prevent="handleSubmit" v-else>
            <div class="form-group">
                <label>Device Code</label>
                <input type="text" class="form-control" v-model="deviceId" placeholder="Device Code" />
            </div>
            <div class="col">
                <label class="form-label">Application</label>
                <select class="form-select" v-model="applicationTypeId">
                    <option value="101">Vega ECDIS</option>
                    <!-- <option value="102">More102</option> -->
                </select>
            </div>
            <div class="col">
                <label class="form-label">OS</label>
                <select class="form-select" v-model="platformId">
                    <option value="1">WinPC</option>
                    <!-- <option value="3">Android</option>
                    <option value="4">iOS</option> -->
                </select>
            </div>
            <div class="form-group">
                <label>Duration (months)</label>
                <input type="number" class="form-control" v-model="durationMonths" />
            </div>
            <div class="form-group my-3">
                <button type="submit" class="btn btn-primary">Get Activation
                    Code</button>
            </div>
        </form>
    </div>
</template>

<script>
import myAxios from "@/services/myAxios";

export default {
    data() {
        return {
            deviceId: null,
            applicationTypeId: 101,
            platformId: 1,
            durationMonths: 12,
            activationCode: null,
            error: null
        }
    },
    methods: {
        handleSubmit() {
            myAxios.post("api/installation/activate", {
                deviceId: this.deviceId,
                applicationTypeId: this.applicationTypeId,
                platformId: this.platformId,
                durationMonths: this.durationMonths
            })
                .then(resp => {
                    this.activationCode = resp.data.id.replace(/(.{4})/g, '$1-').slice(0, -1)
                })
                .catch(ex => { this.error = ex.message })
        }
    }
}
</script>