<template>
    <div>
        <h3>Orders</h3>
        <div class="row mb-2">
            <div class="form-group col">
                <input type="text" class="form-control" v-model="userEmail" placeholder="Email" />
            </div>
            <div class="form-group col">
                <select class="form-select" @change="setStatus($event)">
                    <option>order status</option>
                    <option value="all">all</option>
                    <option value="pre_order">pre order</option>
                    <option value="new">new</option>
                    <option value="complete">complete</option>
                    <option value="owner_approved">owner approved</option>
                    <option value="cancelled_by_distirbutor">cancelled by distirbutor</option>
                    <option value="error">error</option>
                </select>
            </div>
            <div class="form-group col">
                <select class="form-select" @change="setPaymentMethod($event)">
                    <option>payment method</option>
                    <option value="all">all</option>
                    <option value="agreement">agreement</option>
                    <option value="stripe">stripe</option>
                    <option value="yoomoney">yoomoney</option>
                    <option value="trial">trial</option>
                </select>
            </div>
            <div class="form-group col">
                <select class="form-select" @change="setAgreementId($event)">
                    <option>agreements</option>
                    <option value="all">undefined</option>
                    <option v-for="agreement in this.agreements" :key="agreement.id" :value="agreement.id">{{ agreement.name
                    }}</option>
                </select>
            </div>
        </div>
        <div>
            <table class="table table-bordered" :key="tableKey">
                <order v-for="order in orders" :key="order.order.id" :order="order" />
            </table>
        </div>
    </div>
</template>

<script>
import myAxios from "@/services/myAxios";
import Order from "./Order.vue";
import { eventBus } from '@/main';

export default {
    components: { Order },
    data() {
        return {
            orders: [],
            pageSize: 40,
            pageNumber: 1,
            isLoading: false,
            userEmail: null,
            agreementId: null,
            status: null,
            paymentMethod: null,
            agreements: null,
            tableKey: 1
        }
    },
    beforeMount() {
        this.getFirstPage(null, null, null, null, null)
    },
    mounted() {
        this.fetchAgreements()
        window.addEventListener('scroll', this.onScroll);
        eventBus.$on("refreshOrder", (orderId) => { this.refreshOrder(orderId) })
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        getFirstPage() {
            myAxios.post("/api/ordermanager/query", {
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
                userEmail: this.userEmail,
                agreementId: this.agreementId,
                status: this.status,
                paymentMethod: this.paymentMethod
            })
                .then(resp => {
                    this.orders = resp.data.orders
                })
        },
        getNextPage() {
            if (!this.isLoading) {
                this.pageNumber++;
                this.isLoading = true;

                myAxios.post("/api/ordermanager/query", {
                    pageSize: this.pageSize,
                    pageNumber: this.pageNumber,
                    userEmail: this.userEmail,
                    agreementId: this.agreementId,
                    status: this.status,
                    paymentMethod: this.paymentMethod
                })
                    .then(resp => {
                        for (let order of resp.data.orders) {
                            this.orders.push(order);
                        }
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                        this.isLoading = false;
                    })
            }
        },
        fetchAgreements() {
            myAxios.post("/api/agreement/query", {})
                .then(resp => {
                    this.agreements = resp.data
                })
        },
        onScroll() {
            if (
                window.innerHeight + window.scrollY >=
                document.documentElement.scrollHeight - 100
            ) {
                this.getNextPage();
            }
        },
        setStatus(event) {
            if (event.target.value === "all") {
                this.status = null
            }
            else {
                this.status = event.target.value
            }
            this.getFirstPage();
        },
        setPaymentMethod(event) {
            if (event.target.value === "all") {
                this.paymentMethod = null
            }
            else {
                this.paymentMethod = event.target.value
            }
            this.getFirstPage();
        },
        setAgreementId(event) {
            if (event.target.value === "all") {
                this.agreementId = null
            }
            else {
                this.agreementId = event.target.value
            }
            this.getFirstPage();
        },
        refreshOrder(orderId) {
            myAxios.post("/api/ordermanager/query", {
                orderId,
                pageSize: 1,
                pageNumber: 1
            })
                .then(resp => {
                    var orderIndex = this.orders.findIndex(x => x.order.id === orderId)
                    console.log(orderIndex)

                    if (orderIndex !== -1) {
                        this.orders[orderIndex] = resp.data.orders[0];
                        this.tableKey++
                    }
                })
        }
    },
    watch: {
        userEmail: function () {
            this.getFirstPage()
        }
    }
}
</script>

<style>
table {
    font-size: 16px;
}

div.customer-info>div {
    line-height: 18px;
}
</style>