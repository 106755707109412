import myAxios from './myAxios';

export {
    getGeoContent,
    getGeoContents,
    addPermit,
    removePermit,
    getLicenses,
    addUpdateProduct
};

function getGeoContent(id) {
    return myAxios.get('/api/geocontent/' + id);
}

function getGeoContents() {
    return myAxios.get('/api/geocontent/');
}

function addPermit(email, prodid, exp, devnum) {
    return myAxios.get('/api/providers/addpermit', { params: { prodid, email, exp, devnum } })
}

function addUpdateProduct(prod) {
    var prd = JSON.stringify(prod);
    return myAxios.post('/api/providers/AddUpdateProduct', prd, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

function removePermit(userid, prodid) {
    return myAxios.get('/api/providers/removepermit', { params: { prodid, userid } })
}

function getLicenses(gcid) {
    return myAxios.get('/api/providers/getLicenses', { params: { gcid } })
}
