<template>
  <div>
    <div v-if="locale.code==='ru'">
        <h2>mKartWeb портал</h2>
        <h3>Для чего нужен и как работает?</h3>
      <p>
    mKart состоит из бесплатного мобильного приложения mKart App и облачного сервиса для работы с ЭНК (Электронными Навигационными Картами) и навигационными публикациями. mKart поддерживает международный стандарт S-57 и основывается на децентрализованной модели публикации данных, позволяющей владельцам информации предоставлять свои данные пользователям напрямую, без лишней бюрократии и ненужных посредников.
</p>
<h3>
    Пользователи
</h3>
<p>
    Платформа mKart предназначена для двух типов пользователей - издателей и потребителей данных. Издатели могут публиковать карты и навигационные публикации (при условии наличия у них прав на распространение). К издателям относятся, национальные Гидрографические Службы, портовые администрации, коммерческие компании, исследовательские группы, софтверные компании, а также частные пользователи - энтузиасты. Потребителями данных (пользователями клиентских приложений mKart) могут быть штурманы, лоцманы, яхтсмены, туристы и т.д.
</p>
<h3>
    Назначение
</h3>
<p>
    mKart создан для использования в морской картографии, навигации и ряде других сценариев работы с ЭНК:</p>
    <ul>
        <li>оценка ЭНК перед публикацией с целью удостовериться в совместимости новых данных с ранее изданными и
            насколько они соответсвуют задачам навигации;</li>
        <li>валидация и тестирование ЭНК на море;</li>
        <li>обзор ЭНК в комбинации с другими картами и данными;</li>
        <li>публикация ЭНК по мере поступления новых данных;</li>
        <li>составление ЭНК в процессе батиметрических промеров;</li>
        <li>незамедлительное предоставление клиентам доступа к новым ЭНК и другим публикациям;</li>
    </ul>


<h3>Преимущества</h3>
<p>Главным преимуществом для издателей является быстрое, прямое и контролируемое распространение данных в одной из поддерживаемых моделей публикации:</p>
    <ul>
        <li>Индивидуальная – данные доступны только самому издателю;</li>
        <li>Бесплатная – данные доступны всем пользователям клиентских приложений mKart</li>
        <li>Корпоративная – данные доступны ограниченной группе пользователей, назначаемой издателем;</li>
        <li>Коммерческая – данные доступны для продажи пользователям mKart по установленной цене и сроком действия
            лицензии;</li>
    </ul>

<p>Потребителям ЭНК mKart предоставляет эффективную картографическую систему с широким функционалом и
    постоянным доступом к необходимым обновлениям и корректурам.</p>

<h3>Как это работает?</h3>
<p>После регистрации в системе, издатель получает возможность выгружать данные и формировать на их основе различные типы
    продуктов: приватные, бесплатные, корпоративные или коммерческие. После этого данные (продукты) становятся доступны
    пользователям.
</p>
<p>Для доступа к данным клиентам также необходимо зарегистрироваться в системе, на сайте или прямо из приложения. Все
    бесплатные публикации доступны пользователю и без регистрации. Коммерческие продукты могут быть приобретены на сайте
    или прямо из приложения. Корпоративные продукты доступны только для ограниченного круга пользователей который
    назначается издателем. После выбора (и/или покупки) необходимого продукта, соответствующие данные загружаются на
    устройство пользователя.</p>

<h3>Доступные типы публикаций</h3>
<p>mKart поддерживает следующие типы данных для публикации:</p>
    <ul>
        <li>S57 ЭНК – морские навигационные карты;</li>
        <li>bENC – морские карты глубин;</li>
        <li>Каталог ЭНК;</li>
        <li>Батиметрические данные;</li>
        <li>NtM – извещения мореплавателям;</li>
        <li>Огни;</li>
        <li>Лоции;</li>
        <li>AIS - каналы;</li>
        <li>Погода;</li>
    </ul>
    <p>Для удобства  использования и полноты презентации ЭНК и карты глубин отображаются совместно. Кроме того, mKart
    дополняет покрытие картами суши (OSM), в т.ч. картами с 3D рельефом. Отображение карты настраивается в приложении.
</p>

<h3>Как публикуются ЭНК и данные?</h3>
<p>ЭНК и сопутствующие данные публикуются издателем в личном кабинете на сайте mKartWEB.com.</p>

<h5>S57 ЭНК</h5>
<p>Файлы ЭНК в формате S57 загружаются на сайт и тут же конвертируются во внутренний формат, используемый клиентскими
    приложениями mKart.</p>
<h5>bENC</h5>
<p>Публикуются совместно с ЭНК и аналогичным образом конвертируются во внутренний формат.</p>
<h5>Каталог карт ЭНК (ENC & bENC)</h5>
<p>Каталог формируется автоматически, из данных успешно прошедших конвертацию.</p>
<h5>Батиметрические данные</h5>
<p>В некоторых случаях требуется публикация сырых батиметрических данных, собранных при помощи эхолота для использования рыболовами, подводными исследователями, инженерами-строителями и т.д.</p>
<p>Для этого собранные данные должны быть сконвертированы в приложении GPSMapEdit, а затем опубликованы в личном кабинете на mKartWeb.com.</p>
<p>После публикации данные будут автоматически добавлены в каталог. При отображении они будут совмещены с прочими доступными картами.</p>
<h5>AIS - канал</h5>
<p>В соответствующем разделе личного кабинета издателя могут быть заданы ссылки на AIS - каналы, которые должны быть
    включены в продукт.</p>
<h5>Погода</h5>
<p>В соответствующем разделе личного кабинета издателя могут быть размещены ссылки на источники метеорологических
    данных. В общем доступе имеются данные NOAA.</p>
<h5>NtM – извещения мореплавателям</h5>
<p>В разработке.</p>
<h5>Огни</h5>
<p>В разработке.</p>
<h5>Лоции</h5>
<p>В разработке.</p>


<h3>Как лицензируются ЭНК и другие данные?</h3>
<p>В независимости от выбранной модели публикации все публикуемые данные шифруются для того, чтобы гарантировать их целостность и защиту от незаконного распространения. Издатель или выбранный им Дистрибьютор предоставляют отдельным пользователям доступ к данным в разделе Лицензии в личного кабинете. При добавлении лицензии указывается срок её действия и разрешенное количество устройств, на которых пользователь сможет ей воспользоваться.
</p>
<p>Издатель может выдать пользователю лицензию в зависимости от выбранной модели публикации:</p>
    <ul>
        <li><strong>Индивидуальная</strong>
            <p>В данной модели лицензии не выдаются. Опубликованные данные доступно только самому издателю.</p>
        </li>
        <li><strong>Бесплатная</strong>
            <p>После создания бесплатного продукта любой пользователь mKart может получить к нему доступ</p>
        <li><strong>Коммерческая</strong>
            <p>В данной модели при создании продукта необходимо задать его цену, срок действия лицензии и допустимое
                количество устройств. Продукт будет доступен для приобретения в интернет-магазине на сайте mKartWeb, а
                также в AppStore and Google Play. Для продаж в офф-лайне может быть выбран один или несколько
                дистрибьюторов, заинтересованных в распространении продукта.</p>
        </li>
        <li><strong>Корпоративная</strong>
            <p>Лицензии назначаются в личном кабинете издателем или выбранным им дистрибьютором</p>
        </li>
    </ul>


<h3>Это безопасно?</h3>
<p>mKart намного более защищен по сравнению с S63 т.к. не использует передачу открытых ключей в сети. В дополнении к
    этому, при запросах к серверу, лицензия проверяется на валидность и может быть аннулирована автоматически или по
    запросу правообладателя. Более того, компактный и продуманный внутренний формат данных является гарантией
    невозможности их использования вне системы mKart.</p>


<hr>
<h2>Приложение mKart App</h2>
<h3>Как установить?</h3>
<p>mKart App имеет версии для Android, iOS и Windows PC. Приложение может быть загружено из AppStore, Google
    Play или на сайте www.mKart.fi.</p>

<h3>Как зарегистрироваться?</h3>
<p>Для доступа к полному каталогу ЭНК необходимо пройти простую процедуру регистрации в программе или на сайте mKartWEB.
    Укажите ваш емэйл и система направит вам на него пароль для входа. После ввода в программе регистрационных данных
    пользователь получает доступ к каталогу платных, бесплатных и индивидуальных продуктов. Корпоративные продукты видны
    только ограниченному кругу пользователей, по назначению правообладателя.</p>


<h3>Как задать гео-профайл?</h3>
<p>Нужный набор карт выбирается из списка доступных гео-профайлов в нижней части меню программы. Коммерческого продукты становятся доступными для использования после приобретения. ЭНК и сопутствующие данные загружаются программой
    автоматически при наличии интернет-соединения и становятся доступными для последующего использования офф-лайн.</p>

<h3>Как скачать карты и данные?</h3>
<p>После выбора гео-профайла на карте отображаются красные гео-рамки входящих в его состав карт. На масштабах менее 1 :
    5 000 000 гео-рамки меняют цвет в зависимости от статуса загрузки той или иной карты: красный - карта не загружена,
    желтый - карта загружается. После загрузки карты заливка гео-рамки пропадает. Ускорить загрузку той или иной карты
    можно приближая карту в нужной локации.</p>

<h3>Как работать с картой?</h3>
<p>В дополнении к обычному перемещению, карту можно вращать, двигаясь пальцем вдоль нижней границы сенсорного экрана. Наклон карты можно менять, перемещая палец вдоль
    левой или правой границы сенсорного экрана. Тап по карте позволяет отобразить информацию о соответствующем объекте на
    карте и доступные параметры указанной области. Удобным дополнением при работе с картой является отображение шкалы
    пеленга (Bearing Range/Rings).
</p>

<h3>Как использовать шкалу пеленга?</h3>
<p>Шкала является своего рода заменой ERBL применительно к особенностям реализации мобильных приложений. В режиме "Вращать по курсу" пеленг привязан к COG, но может быть задан и вручную путем вращения карты. Информация об объектах в зоне пеленга выводится на экран автоматически, например, путевые точки, цели AIS, огни и т.д. В режиме компаса пеленг привязывается к перемещению устройства позволяя получать информацию об объектах по курсу.
</P>
<p>Шкала компаса всегда отображается по верхней границе карты. Шкала пеленга может быть отключена по тапу на иконку в левой нижней части экрана. По центру шкалы пеленга могут отображаться текущие глубина и координаты.</p>

<h3>Как выбрать источник позиционирования?</h3>
<p>Данные о позиции, курсе и скорости могут быть устанволе6ны в меню Ship’s sensors:</p>
<ul>
        <li>Выкл – нет данных;</li>
        <li>GPS – встроенный GPS устройства;</li>
        <li>Симулятор – программный симулятор mKart;</li>
        <li>WEB симулятор – для подключения к mKart WEB симулятору;</li>
        <li>mKart Server – для подключения к другим устройствам;</li>
        <li>AIS кана – данные из интернет-канала AIS;</li>
    </ul>

<h3>Как отобразить данные AIS?</h3>
<p>Если используемый гео-профайл содержит данные AIS они будут автоматически отображены по мере поступления. Если в зоне пеленга появляется выбранная цель, то программа выводит окно с информацией о данной цели. Любая из целей AIS может быть выбрана для отображения как собственное судно.</p>

<h3>Как пользоваться поиском?</h3>
<p>Все данные, содержащиеся в ЭНК доступны для поиска в приложении. Карта позиционируется на найденном объекте и на экран выводится вся имеющаяся информация о нём.</p>

<h3>Какие еще функции доступны на карте?</h3>
<ul>
    <li>Отображение глубин менее заданного  значения – используется для отображения безопасных глубин для прохода судна;
    </li>
    <li>Отображение  заливки менее заданного  значения – для поиска места стоянки и рыбной ловли.</li>
</ul>



<h3>Как переключаться между режимами Компас и Вращать по курсу?</h3>
<p>Соответствующая кнопка находится в левой нижней части экрана карты. В режиме Вращать по курсу карта всегда
    разворачивается по направлению COG, в режиме Компаса карта вращается в зависимости от положения устройства. Угол
    наклона устройства позволяет менять наклон карты от 2D если устройство лежит на горизонтальной поверхности до 3D
    если держать устройство вертикально.
</p>

<h3>Как строить маршрут?</h3>
<p>Для создания нового или редактирования ранее созданного маршрута воспользуйтесь кнопкой Маршрут  в левой нижней части
    экрана карты. После выбора маршрута из списка (или создания нового) доступны следующие функции редактирования
    маршрута:</p>
    <ul>
        <li>Добавить;</li>
        <li>Переместить;</li>
        <li>Вставить промежуточную точку;</li>
        <li>Удалить;</li>
    </ul>
    <p>При планировании доступна и автоматическая прокладка маршрута.</p>

<h3>Как работает автоматическая прокладка  маршрута?</h3>
<p>Автоматическая прокладка маршрута может использоваться исключительно в справочных целях и носит рекомендательный
    характер. В иных случаях данный функционал используется на свой страх и риск.
    Для прокладки маршрута следует задать точку старта и финиша, указав их непосредственно на карте или воспользовавшись
    поиском. Если маршрут ранее был задан, добавление  новых точек приведет к автоматическому  перестроению маршрута. При
    каждом перестроении маршрута система автоматически проверяет его на безопасность.</p></div>
    <div v-else>
      <h2>About Platform</h2>
      <h3>What is this all about?</h3>
      <p>
    mKart is combination of WEB based platform and free mobile applications providing nautical ENCs and data publishing for utilizing in fast and effective way.  Once data collected and processed, they can be immediately delivered to clients to be used for various purposes and in various environments.  mKart is based on s-57 International standard and stipulates non-centralized data publishing model offering data owners direct links to data customers avoiding unnecessary commercial and bureaucratic intermediators.
</p>
<h3>
    Who are the clients?
</h3>
<p>
    There are two types of clients – data publishers and data users. The data publisher is those who poses information and has legal rights of its publishing and distribution. They include (but not restricted) national HOs, port authorities, commercial companies, surveying divisions, OEM SW companies, and individual data collectors, etc. On the data use side in addition to mentioned above mKart deals with pilots, navigators, yachtsmen, tourists and similar. 
</p>
<h3>
    What are purposes?
</h3>
<p>
    mKart is designed for ENCs and other navigational data production and use for the cases like following</p>
    <ul>
        <li>To evaluate new ENC in pre-release mode in order to understand how new data fit with old ones and how it copes with navigational purposes;</li>
        <li>To verify and validate new ENCs at sea;</li>
        <li>To see ENCs in combination with other maps and data by “sailor’s eyes”;</li>
        <li>To make ENCs release right after new data received;</li>
        <li>To make ENCs while and in parallel with bathymetric survey;</li>
        <li>To make new ENCs and publications available to customers with no delay;</li>
    </ul>


<h3>What are benefits?</h3>
<p>Main publisher’s advantage is direct, immediate and controllable data distribution within one of the following models:</p>
    <ul>
        <li>Individual mode – no one except data publisher may watch, access or use ENCs and other data published;</li>
        <li>Free mode – everybody can see and use data via mKart Apps;</li>
        <li>Corporative mode – ENCs and data available within limited grope of customers managed by data owner;</li>
        <li>Commercial mode – ENC and data can be purchased commercially at defined price and period;</li>
    </ul>

<p>To ENCs and data users mKart offers most fast and effective cartographic system with number of functionality suites virality of needs on base of relevant updated ENCs and information from direct source.</p>

<h3>How does it work?</h3>
<p>At first data publisher has to pass registration and create geo profile. To make ENCs and data published the data
    owner has to upload ENC and other data on mKart WEB platform and assign data distribution scenario – individual,
    free,
    corporative or commercial. Since then data will be available to customers.</p>
<p>To get access to publications data client needs to pass registration ether in App or on WEB site to get access to
    full
    list of published profiles. While activated the desired ones and in case of having corresponding license from data
    owner
    ENCs and other publications will be automatically uploaded on his device.</p>

<h3>What types of data are supported?</h3>
<p>mKart maintains the following data types:</p>
    <ul>
        <li>S57 ENC – navigation nautical charts;</li>
        <li>bENC – bathymetric nautical charts;</li>
        <li>ENCs Catalogue;</li>
        <li>Bathymetric data;</li>
        <li>NtM – Notice to Mariners;</li>
        <li>List of Lights;</li>
        <li>Sailing Directions;</li>
        <li>Pilot books;</li>
        <li>AIS stream;</li>
        <li>Weather;</li>
    </ul>
    <p>ENCs and bENCs are being merged by default to present consistent presentation and data use. Apart from that mKart
    system
    performs automatic ENCs and bENCs intergration with OSM and 3D terrestrial data. The data integration options to be
    selected and activated via mKart App setup menu.</p>

<h3>How to make ENCs and data published?</h3>
<p>The general way of making data published is upload on mKart WEB platform in registered geo profile.</p>

<h5>S57 ENC</h5>
<p>ENC S57 compliant files to be copied in ENC directory, after that they will be converted and published in protected
    preoperatory data form to become available to data clients.</p>
<h5>bENC</h5>
<p>It works like above; bENC files should be uploaded in ENC directory. In mKart APP ENC and bENC will be automatically
    integrated.</p>
<h5>ENC and bENC Catalogue</h5>
<p>Generated automatically upon files conversion as above.</p>
<h5>Bathymetric data publishing</h5>
<p>In some cases, it is required to make row bathymetric data published in form of unprofessional and non S57 compliant
    maps on base of surveyed depth sounding with purpose of using for fishing, underwater research, engineerial
    constructions, etc.</p>
<p>To do so collected data hast to processed and converted by one o the available program – GPS Map Edit, for example,
    to
    make bENC in mKart format. After that file to be transferred to ENC directory as above.</p>
<p>After newly processed data are published, they will be automatically merged for presentation and use with standard
    ENC/bENCs and OSM terrestrial data. Thus, new chart is created.</p>
<h5>AIS stream</h5>
<p>The corresponding link to the AIS source should be indicated that directory.</p>
<h5>Weather</h5>
<p>The corresponding link to the weather source should be indicated that directory. If there is no local, NOAA data can
    be
    used.</p>
<h5>NtM – Notice to Mariners</h5>
<p>Has to be processed by mKart convertor first. Under development.</p>
<h5>List of Lights</h5>
<p>Has to be processed by mKart convertor first. Under development.</p>
<h5>Sailing Directions</h5>
Has to be processed by mKart convertor first. Under development.
<h5>Pilot books</h5>
<p>Has to be processed by mKart convertor first. Under development.</p>

<h3>How ENCS and data licensing mechanism works?</h3>
<p>Irrespectively from data distribution model (see above) all ENCs and data the subject of encryption and protection
    in
    order to guarantee its safe and integrity. Data Publisher or assigned Distributer in his behalf can select
    individual
    customer’s e-mail to submit necessary permissions via mechanism available on mKart WEB platform Publisher’s
    geo-profile.
    Period and number of devices are licenses parameters. License termination can also be performed here.
</p>
<p>Data publisher may submit license for data use to individual clients using scenarios like following:</p>
    <ul>
        <li><strong>Individual mode</strong>
            <p>In this case Data Publisher is not able to give license to anybody else. ENCs and data remains tied to
                him
                personally.</p>
        </li>
        <li><strong>Free mode</strong>
            <p>All can access and use ENC and data with no limits and restrictions.</p>
        <li><strong>Commercial mode</strong>
            <p>To use this scenario Data Publisher has to assign prices and time limits to be purchased by individual
                clients. He
                also
                should make this information available via AppStore and Google Play. The Distributer also can be
                appointed for the
                distribution in this case.</p>
        </li>
        <li><strong>Corporative mode</strong>
            <p>This model implies distribution within limited groups of customers getting licenses directly from
                Publisher or
                Distributer appointed.</p>
        </li>
    </ul>


<h3>Is it secured?</h3>
<p>It has the higher level of security and data protection than S63 algorithm because doesn’t imply open keys transfer
    over
    Internet. Another reason is licenses are subject of regular checks and validation when server is connected. Besides
    of
    that compact and sophisticated SENC format is the guarantee data cannot be used outside mKart system.</p>


<hr>
<h2>ABOUT mKART APP</h2>
<h3>How to find SW and get started?</h3>
<p>mKart App is developed for three operational systems – iOS, Android and Window. App can be found on AppStore, Google
    Play and on www.mKart.fi.</p>

<h3>How to pass App registration?</h3>
<p>In order to get access to full list of ENCs and nautical publications one can pass registration via submitting
    e-mail
    and getting password. After credentials are activated list pf geo-profiles will be automatically uploaded in the
    application.</p>


<h3>How to select geo-profile (maps folio)?</h3>
<p>To activate desired maps folio it should be selected in the geo-profile list (low part of menu). Fore commercial
    types
    of profiles corresponding purchase have to be performed first. ENCs and data comes to device automatically while
    Internet connected and stay there for off-line use.</p>

<h3>How to upload ENCs and publications?</h3>
<p>After profile is selected maps frames filled red will be displayed. At scale less 1:5 000 000 maps to downloaded
    automatically change colors from red – available, yellow – in queue and natural – downloaded. One may enforce
    individual
    maps upload via zooming out at particular location.
    Upload and work with other publications to be done via corresponding activation in the profile with getting access
    to
    corresponding functionality.</p>

<h3>How to manipulate with ENCs?</h3>
<p>ENCs manipulations are to be performed standard gestures. In addition to that rotation can be done via swipe from
    left
    to right and vice a versa on the low bar. And maps inclination to be performed by swiping on the left and right
    screen’s
    sides. To retrieve maps objects info, one needs to click in position. The same is fair to collect information about
    objects shown on map. The specific SW feature is Bearing Range/Rings tool to work with map with less interrogation.
</p>

<h3>How to use Bearing Range/Rings tool?</h3>
<p>This tool is sort of typical ERBL substitution with taken into account mobile specifics. In Course up mode Bearing
    line
    tied to the COG but can be oriented manually along with map rotation. Information about objects on Bearing line will
    pop
    up automatically such as way points, AIS targets, lights. In Compass mode bearing line will be oriented by device
    direction allowing to pick information on its way.
</P>
<p>Compass scale is always presented on the top bar of the map. Bearing line can be ON/OFF via icon in the low left
    side on
    map. In the Bearing line center ether depth or coordinates can be displayed.</p>

<h3>How to select nav data source?</h3>
<p>The data about own ship’s position, course and speed can be selected from following sources in Menu item Ship’s
    sensors:</p>
    <ul>
        <li>Off – no data;</li>
        <li>GPS – embedded device GPS;</li>
        <li>Embedded Ships Simulator – embedded SW simulator;</li>
        <li>WEB simulator – to be connected to mKart WEB sim;</li>
        <li>mKart Server – to be connected with another device;</li>
        <li>AIS stream – data from WEB stream;</li>
    </ul>

<h3>How to display AIS data?</h3>
<p>In case activated geo-profile contains AIS WEB stream this data also to be displayed. The corresponding AIS scale
    will
    be indicated in the top map bar highlighted when targeted is on bearing line. AIS target can be assigned as own
    ship.</p>

<h3>How to use Search function?</h3>
<p>All maps data and information existing within system is searchable. After data found the object will be displayed on
    map
    with context menu as appropriate. Different types of information implies different functionality.</p>

<h3>How to use extra maps features?</h3>
<p>There are two extra maps features:</p><ul>
        <li>To display depth less than – to show safe navigation area on map;</li>
        <li>To display gradient less than – to find anchor area and for the fishing purpose;</li>
    </ul>



<h3>How to switch between Compass and Course Up mode?</h3>
<p>The icon to switch between two modes is placed in low left chart area. In Course up mode map is oriented by COG, in
    Compass mode by device direction with automatic adjustment 2D/3D inclination from flat 2D to vertical 3D position.
</p>

<h3>How to use make Route?</h3>
<p>To make new or edit existing route one should push Rote button in low bar. After route selection way points editing
    functions became available via context menu including:</p>
    <ul>
        <li>To add;</li>
        <li>To move;</li>
        <li>To insert;</li>
        <li>To delete;</li>
    </ul>
    <p>Automatic route calculations option can be also be used here.</p>

<h3>How to use Automatic Route calculation?</h3>
<p>Automatic route calculation to be used entirely at navigator’s risk and should be taken as recommendation only.
    Start
    and Finish points to be indicated on map or found via Search. In case if route is already presented automatically
    built
    one will be merged. Safety check procedure unconditionally applies every time when automatic routing is done.</p></div>
  </div>
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  }
};
</script>